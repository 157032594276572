.rotate-message-wrapper {

  display: none;
  background: #000000F0;
  top: 0;
  left: 0;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 23;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.rotate-message-image {

  height: 4rem;
  margin-bottom: 1rem;
}

.rotate-message {

  color: #edeae4;
}

@media only screen and (max-width: 600px) {

  .rotate-message-wrapper {

    display: inherit;
  }
}