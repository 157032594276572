.story-page .deck-list {

	width: 100vw;
	overflow: auto;
	flex-wrap: nowrap;
	position: relative;
	justify-content: left;
	padding: 0 0.5rem;
}

.story-params {

	width: 100%;
	height: 100%;
	padding: 3.5rem;
	padding-top: 4.5rem;
	padding-bottom: 2rem;
	box-sizing: border-box;
	flex-direction: column;
	align-items: center;
}

.story-mode-changes {

	position: absolute;
	top: 4rem;
	left: calc(50vw - 12.5vh);
	width: 25vh;
	height: 25vh;
}

.story-mode-changes .mode-change {

	position: absolute;
	width: 4rem;
	height: 4rem;
	top: calc(12.5vh - 2rem);
	left: -4rem;
	font-size: 4rem;
	line-height: 4rem;
	color: #dec6a2;
	cursor: pointer;
	justify-content: right;
	box-sizing: border-box;
	padding: 0;
	transition: padding 0.2s;
	filter: drop-shadow(0 0 0.8vh black);
}

.story-mode-changes .mode-change + .mode-change {

	left: unset;
	right: -4rem;
	justify-content: left;
}

.story-mode-changes .mode-change img {

	height: 80%;
	margin: 10%;
	transform: scaleX(-1);
}

.story-mode-changes .mode-change + .mode-change img {

	transform: scaleX(1);
}

.story-mode-changes .mode-change:hover {

	padding-right: 0.3rem;
}

.story-mode-changes .mode-change + .mode-change:hover {

	padding-right: unset;
	padding-left: 0.3rem;
}

.story-icon {

	position: relative;
	width: 25vh;
	height: 25vh;
	opacity: 1;
}

.story-icon img {

	object-fit: contain;
	width: 100%;
	height: 100%;
	filter: sepia(1) drop-shadow(0 0 0.8vh black)
}

.story-title {

	color: #dec6a2;
	margin: 1rem;
	font-size: 2.5rem;
}

.story-deck-section {

	flex-direction: column;
	align-items: center;
	height: 12rem;
}

.story-deck {

	font-size: 0.9rem;
}

.story-deck-select {

	position: relative;
	width: 20rem;
	max-width: 98vw;
	font-size: 1.5vh;
	overflow: auto;
}

.story-deck-select::before, .story-deck-select::after {

	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 2px;
	height: 100%;
	background: linear-gradient(to bottom, transparent, #dec6a2, transparent);
	z-index: 1;
}

.story-deck-select::after {

	left: unset;
	right: 0;
}

@media (min-width: 601px) {

	.story-deck-select .card-list .listed-deck {

		font-size: 0.8rem;
	}

	.story-deck-select {

		width: 32rem;
	}

	.story-deck-section {

		height: 16rem;
	}

	.story-deck {

		font-size: 1.2rem;
	}

}