.game-campfire .lightbox-container {

  background: #000000D0;
}

.campfire-choice {

  justify-content: center;
  font-size: calc(0.8vw + 1.4vh);
  line-height: calc(1.2vw + 2.2vh);
  margin: 2vh 0;
  color: #edeae4;
  cursor: pointer;
  background: linear-gradient(to left, transparent, #60606080, transparent);
  transition: background 0.2s ease-in-out;
}

.campfire-choice:hover {

  background: linear-gradient(to left, transparent, #606060FF, transparent);
}

.campfire-choice-inner {

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.campfire-choice h3 {

  font-size: inherit;
  font-weight: bold;
}

.campfire-choice .fight-loot-piece {

    height: calc(1vw + 1.5vh);
    padding: calc(0.15vw + 0.2vh) calc(0.15vw + 0.2vh);
    margin-bottom: 0;
    opacity: 0.7;
}

.campfire-choice .loot-icon {

  font-size: calc(0.8vw + 1.1vh);
}

.campfire-choice .campfire-info-hp {

	color: #ff8181;
}

.campfire-choice .campfire-info-exp {

	color: #ffdf81;
}

.campfire-choice .campfire-info-corruption {

  color: #ff81ed;
}

.campfire-loot-pieces {

  flex-wrap: wrap;
  justify-content: center;
}