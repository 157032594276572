.play-page .main {

	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.play-buttons {

	justify-content: center;
	width: 80%;
	height: 90%;
	flex-direction: column;
}

.play-buttons .menu-button-container {

	margin: calc(-0.1rem + 2.5vh) 1rem;
}

@media only screen and (max-width: 600px) {

}

@media only screen and (max-height: 550px) {

	.play-buttons {

		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		height: calc(80% - 2rem);
		margin-top: 2rem;
	}

	.play-buttons .menu-button-container {

		height: 2rem;
		width: calc(50% - 2rem);
	}
}