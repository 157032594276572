.game-entity {

  height: 42.400000000000006vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  pointer-events: none;
}

.game-entity {

  animation: game-entity-entry-opacity 0.5s ease-out;
}

@keyframes game-entity-entry-opacity {
  from { opacity: 0; }
  to { opacity: 1; }
}

.game-entity .game-entity-main-wrapper {

  animation: game-entity-entry-shift 0.5s ease-out;
}

@keyframes game-entity-entry-shift {
  from { transform: translateX(8vw); }
  to { transform: translateX(0); }
}

.game-champion .game-entity .game-entity-main-wrapper {

  animation: game-entity-champion-entry-shift 0.5s ease-out;
}

.game-entity .game-entity-main {

  position: relative;
}

@keyframes game-entity-champion-entry-shift {
  from { transform: translateX(-8vw); }
  to { transform: translateX(0); }
}

.game-entity .game-entity-img-wrapper {

  height: 33.6vh;
  margin-bottom: 1.2000000000000002vh;
  position: relative;
  pointer-events: all;
}

.dead .game-entity .game-entity-img-wrapper {

  pointer-events: none;
}

.game-entity.size-small .game-entity-img-wrapper {

  height: 24.0vh;
  margin-top: 9.600000000000001vh;
}

.game-entity.size-heavy .game-entity-img-wrapper {

  height: 42.0vh;
  margin-top: -8.00000000000001vh;
}

.game-entity.size-massive .game-entity-img-wrapper {

  height: 55.0vh;
  margin-top: -21.00000000000001vh;
}

.game-entity .game-entity-img-wrapper img {

  height: 100%;
  animation: entity-img-size 2s ease-in-out infinite alternate;
}

@keyframes entity-img-size {
  from { transform: scaleY(1.0) translateY(0); }
  to { transform: scaleY(0.97) translateY(0.6000000000000001vh); }
}

.target-enemy:not(.dead) .game-entity .game-entity-img-wrapper {

  cursor: pointer;
}

.target-enemy:not(.dead) .game-entity .game-entity-img-wrapper img {

  filter: drop-shadow(0 0 1.5vh red);
}

.game-entity-shadow {

  position: absolute;
  bottom: -2.4000000000000004vh;
  left: 4.800000000000001vh;
  height: 7.2vh;
  width: 24.0vh;
  border-radius: 100%;
  background: #00000080;
  box-shadow: 0 0 0.48vh 0.48vh #00000080;
  z-index: -1;
}

.game-entity.size-small .game-entity-shadow {

  left: 4.0vh;
  height: 4.800000000000001vh;
  width: 16.0vh;
  bottom: -1.2000000000000002vh;
}

.game-entity.size-heavy .game-entity-shadow {

  left: 5vh;
  height: 9.2vh;
  width: 32.0vh;
  bottom: -2.700000000000002vh;
}

.game-entity.size-massive .game-entity-shadow {

  left: 5.2vh;
  height: 13vh;
  width: 46.0vh;
  bottom: -3.000000000000002vh;
}

.game-entity .game-entity-life-wrapper {

  height: 1.2000000000000002vh;
  width: 38.400000000000006vh;
  position: relative;
}

.game-entity.size-small .game-entity-life-wrapper {

  width: 24.0vh;
}

.game-entity.size-heavy .game-entity-life-wrapper {

  width: 42.0vh;
}

.game-entity.size-massive .game-entity-life-wrapper {

  width: 55.0vh;
}

.game-entity .game-entity-life-text {

  position: absolute;
  width: 38.400000000000006vh;
  justify-content: center;
  color: white;
  font-size: 3.6vh;
  top: -1.8vh;
  text-shadow: 0.36000000000000004vh 0.36000000000000004vh 0.24vh #00000080;
  z-index: 1;
}

.game-entity.size-small .game-entity-life-text {

  width: 24.0vh;
}

.game-entity.size-heavy .game-entity-life-text {

  width: 42.0vh;
}

.game-entity.size-massive .game-entity-life-text {

  width: 55.0vh;
}

.game-entity .game-entity-life {

  height: 1.2000000000000002vh;
  width: 38.400000000000006vh;
  background: #3e1f1f82;
  border-radius: 0.30000000000000004vh;
  overflow: hidden;
  box-shadow: 0.36000000000000004vh 0.36000000000000004vh 0.24vh 0px #00000080;
}

.game-entity.size-small .game-entity-life {

  width: 24.0vh;
}

.game-entity.size-heavy .game-entity-life {

  width: 42.0vh;
}

.game-entity.size-massive .game-entity-life {

  width: 55.0vh;
}

.game-entity .game-entity-life .game-entity-life-fill {

  height: 100%;
  background: red;
}

.game-entity .game-entity-life.block .game-entity-life-fill {

  background: #ae8300;
}

.game-entity .game-entity-life.invincibility .game-entity-life-fill {

  background: #006cae;
}

.game-entity .game-entity-life .game-entity-life-fill.poison {

  height: 100%;
  background: #006800;
  transform: translateX(-100%) translateX(1px);
}

.game-entity .game-entity-block {

  height: 4.800000000000001vh;
  width: 4.800000000000001vh;
  position: absolute;
  top: -1.8vh;
  left: -1.2000000000000002vh;
  z-index: 1;
}

.game-entity .game-entity-block .game-entity-block-img {

  height: 100%;
  width: 100%;
  position: absolute;
  top: 1px;
  left: 0;
}

.game-entity .game-entity-block .game-entity-block-text {

  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 0.24vh 0.24vh 0.12vh #000000A0;
}

.game-entity .game-entity-statuses {

  position: relative;
  width: 100%;
  margin-top: 1.2000000000000002vh;
  height: 4.800000000000001vh;
}

.game-entity .game-entity-status-wrapper {

  height: 4.8vh;
  width: 4.8vh;
  position: relative;
}

.game-entity .game-entity-status {

  height: 4.8vh;
  width: 4.8vh;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: all;
}

.game-entity .game-entity-status .game-entity-status-img {

  height: 100%;
  width: 100%;
  position: absolute;
  top: 1px;
  left: 0;
}

.game-entity .game-entity-status .game-entity-status-text {

  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  justify-content: center;
  align-items: center;
  color: white;
  text-shadow: 0.24vh 0.24vh 0 #000000A0;
}

.game-entity .game-entity-intent {

  height: 7.2vh;
  width: 7.2vh;
  position: absolute;
  top: -6.0vh;
  left: calc(50% - 2.8000000000000003vh);
  animation: intent-move 1.3s ease-in-out alternate infinite;
  pointer-events: all;
  transition: opacity 0.4s ease-out;
  animation: game-intent-appear 0.4s 1 ease-out, intent-move 1.3s ease-in-out alternate infinite;
}

@keyframes game-intent-appear {
  from { opacity: 0 }
  to { opacity: 1 }
}

.game-entity .game-entity-intent.intent-anim-img {

  opacity: 0;
}

.game-entity.size-small .game-entity-intent {

  top: 1.2000000000000002vh;
  animation: game-intent-appear 0.4s 1 ease-out, intent-move-small 1.3s ease-in-out alternate infinite;
}

.game-entity.size-heavy .game-entity-intent {

  top: -12vh;
  animation: game-intent-appear 0.4s 1 ease-out, intent-move-heavy 1.3s ease-in-out alternate infinite;
}

.game-entity.size-massive .game-entity-intent {

  top: -14vh;
  animation: game-intent-appear 0.4s 1 ease-out, intent-move-massive 1.3s ease-in-out alternate infinite;
}

.game-entity .game-entity-intent::before {

  content: "";
  height: 2.4000000000000004vh;
  width: 2.4000000000000004vh;
  position: absolute;
  top: 2.4000000000000004vh;
  left: 2.4000000000000004vh;
  border-radius: 50%;
  background: #00000060;
  box-shadow: 0 0 2.16vh 2.16vh #00000060;
}

@keyframes intent-move {
  from { top: -6.0vh }
  to { top: -7.2vh }
}

@keyframes intent-move-small {
  from { top: 1.2000000000000002vh }
  to { top: 0.0vh }
}

@keyframes intent-move-heavy {
  from { top: -12vh }
  to { top: -13.2vh }
}

@keyframes intent-move-massive {
  from { top: -14vh }
  to { top: -15.2vh }
}

.game-entity .game-entity-intent .game-entity-intent-img {

  height: 100%;
  width: 100%;
  position: absolute;
  top: 1px;
  left: 0;
}

.game-entity .game-entity-intent .game-entity-intent-text {

  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  justify-content: center;
  align-items: center;
  color: #ffe8e8;
  font-size: 5.28vh;
  text-shadow: 0.24vh 0.24vh 0 #000000A0;
}

.game-entity .game-entity-intent .game-entity-intent-text .intent-times {

  font-size: 3.7vh;
  color: #ffe8e8;
}

@keyframes intent-appear {
  from { opacity: 0 }
  to { opacity: 1 }
}

.game-entity .game-entity-damage {

  position: absolute;
  height: 12.0vh;
  width: 12.0vh;
  top: calc(50% - 6.0vh);
  left: calc(50% - 6.0vh);
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12vh;
  text-shadow: 0.36000000000000004vh 0.36000000000000004vh 0.24vh #00000080;
  opacity: 0;
}

.game-entity .game-entity-afflict-status {

  position: absolute;
  height: 12.0vh;
  width: 12.0vh;
  top: calc(50% - 6.0vh);
  left: calc(50% - 6.0vh);
}

.game-entity .game-entity-afflict-status-text {

  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 4.800000000000001vh;
  text-shadow: 0.36000000000000004vh 0.36000000000000004vh 0.24vh #00000080;
  opacity: 0;
}

.game-entity .debuff .game-entity-afflict-status-text {

  color: #f9c9c9;
}

.game-entity .game-entity-afflict-status-img {

  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.game-entity.status-hidden .game-entity-img-wrapper .game-entity-main-img {

  opacity: 0.3;
}

.game-entity .game-entity-main .game-entity-name {

  position: absolute;
  opacity: 0;
  bottom: 2vh;
  left: 0;
  width: 100%;
  text-align: center;
  justify-content: center;
  text-shadow: 0.36000000000000004vh 0.36000000000000004vh 0.24vh #00000080;
  color: white;
  font-size: 3.6vh;
  transition: opacity 0.1s ease-in-out;
}

.enemy-wrapper .game-entity .game-entity-main .game-entity-img-wrapper, .game-merchant .game-entity .game-entity-main .game-entity-img-wrapper {

  transition: filter 0.1s ease-in-out;
}

.enemy-wrapper .game-entity .game-entity-main:hover .game-entity-img-wrapper, .game-merchant .game-entity .game-entity-main:hover .game-entity-img-wrapper {

  filter: brightness(0.9) contrast(0.9);
}

.enemy-wrapper .game-entity .game-entity-main:hover .game-entity-name, .game-merchant .game-entity .game-entity-main:hover .game-entity-name {

  opacity: 1;
}

@media only screen and (max-height: 550px) {

  .game-entity .game-entity-status-wrapper {
    
      height: 6vh;
      width: 6vh;
  }

  .game-entity .game-entity-status {
    
      height: 6vh;
      width: 6vh;
  }

  .game-entity .game-entity-status-wrapper + .game-entity-status-wrapper {
    
      margin-left: -0.5vh;
  }

  .game-entity .game-entity-life-text {

    font-size: 4.2vh;
    top: -2.1vh;
  }

  .game-entity .game-entity-intent {
    height: 9vh;
    width: 9vh;
    top: -9vh;
    left: calc(50% - 4.2000000000000003vh);
  }

  .game-entity .game-entity-intent .game-entity-intent-text {
    font-size: 6.8vh;
    text-shadow: 0.3vh 0.3vh 0 #000000A0;
  }

  @keyframes intent-move {
    from { top: -7.4vh }
    to { top: -8.6vh }
  }

  @keyframes intent-move-small {
    from { top: -0.2vh }
    to { top: -1.4vh }
  }

  @keyframes intent-move-heavy {
    from { top: -13.4vh }
    to { top: -14.6vh }
  }

  @keyframes intent-move-massive {
    from { top: -14.4vh }
    to { top: -16.6vh }
  }
}