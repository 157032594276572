.redirect-finalized {

  position: absolute;
  width: 100%;
  justify-content: center;
  left: 0;
  top: calc(50% + 2rem + 3vh);
  animation: redirect-appear 0.8s ease-out;
}

@keyframes redirect-appear {
  from { opacity:0 }
  to { opacity:1 }
}