.fight-reward-wrapper .lightbox-container {

  background: #000000D0;
  animation: reward-appear 0.8s ease-in-out;
}

@keyframes reward-appear {
  from { opacity:0 }
  to { opacity:1 }
}

.fight-reward {
  
  z-index: 20;
}

.fight-reward .lightbox {

  color: #edeae4;
  width: calc(10vw + 15vh);
}

.fight-reward h1 {

  text-align: center;
  align-self: center;
  margin-top: 4vh;
  margin-bottom: 4vh;
  font-size: calc(1.3vw + 1.8vh);
  line-height: calc(1.3vw + 1.8vh);
  white-space: nowrap;
  justify-content: center;
}

.fight-loot {

  flex-direction: column;
  height: calc(6vw + 14vh);
  justify-content: center;
  margin: 4vh 0;
}

.fight-loot-piece {

  align-items: center;
  justify-content: center;
  height: calc(1.5vw + 2vh);
  padding: calc(0.15vw + 0.2vh) calc(0.15vw + 0.2vh);
  margin-bottom: calc(0.75vw + 1vh);
  /*cursor: pointer;*/
  transition: background 0.2s;
}

.fight-loot-piece:hover {

  /*background: #39372e40;*/
}

.loot-icon {

  height: 100%;
  font-size: calc(1vw + 1.5vh);
}

.loot-icon img {

  height: 100%;
  margin-right: calc(0.25vw + 0.4vh);
}

.loot-icon span {

  height: 100%;
  align-items: center;
  color: #d9d6d0;
  transition: color 0.2s;
}

/*.fight-loot-piece:hover .loot-icon span {

  color: inherit;
}*/

.fight-reward-end {

  justify-content: center;
  font-size: calc(0.8vw + 1.4vh);
  height: calc(1.2vw + 2.2vh);
  line-height: calc(1.2vw + 2.2vh);;
  color: #edeae4;
  cursor: pointer;
  background: linear-gradient(to left, transparent, #00000080, transparent);
  transition: background 0.2s ease-in-out;
}

.fight-reward-end:hover {

  background: linear-gradient(to left, transparent, #000000FF, transparent);
}

.fight-loot-card {

  background: #000000D0;
}

.fight-loot-card-options {

  width: 80vw;
  margin: auto;
  justify-content: space-between;
}

.fight-loot-card-option {

  flex-direction: column;
  align-items: center;
}

.fight-loot-card-option .fight-loot-card-option-card {

  margin-bottom: 2rem;
  cursor: pointer;
}

.fight-reward-champion {

  position: absolute;
  top: 10%;
  height: 90%;
  left: calc(20vw - 10vh);
  transform: scaleX(-1);
}

.fight-reward-info {

  position: absolute;
  top: 10%;
  height: 80%;
  width: 60vh;
  max-width: 40vw;
  left: 50%;
  flex-direction: column;
  color: white;
  justify-content: center;
}