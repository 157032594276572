.level-up-title {

  margin: auto;
  margin-bottom: 1rem;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 0.15rem 0.15rem 0.1rem #00000080;
  color: #13cc6d;
}

.level-up-comparison {

  margin: auto;
  margin-top: 0vw;
  margin-bottom: calc(6vh - 2vw);
  justify-content: center;
  align-items: center;
}

.level-up-comparison .level {
  
  height: calc(19vh + 4.75vw);
  width: calc(19vh + 4.75vw);
  position: relative;
}

.level-up-comparison .level img {
  
  height: 100%;
  width: 100%;
}

.level-up-comparison .level-indicator {
  
  height: calc(3.4vh + 1vw);
  font-size: calc(3.4vh + 1vw);
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: -2vh;
  left: 0;
  font-weight: bold;
  text-shadow: 0.15rem 0.15rem 0.1rem #00000080;
  color: red;
}

.level-up-comparison .arrow {
  
  height: 5vh;
  width: 15vh;
}

.level-up-screen .fight-loot-card-options {

  width: calc(60vh + 40vw);
  max-width: 80vw;
  margin: auto;
  justify-content: space-between;
}

.level-up-screen .fight-loot-card-option .fight-loot-card-option-card {

  font-size: calc(0.55vw + 1.5vh);
  margin-bottom: 1.5vh;
}

.fight-loot-card-option .fight-loot-card-option-card .fight-loot-card-option-card-inner {

  margin-left: -0.2em;
  transition: margin 0.2s, font-size 0.2s;
}

.level-up-screen .fight-loot-card-option .fight-loot-card-option-card:hover .fight-loot-card-option-card-inner {

  margin-left: -0.5em;
  margin-top: -0.5em;
  font-size: 1.1em;
}

.level-up-screen .fight-loot-card-option .game-card-info {

  position: static;
  width: calc(8vw + 18vh);
  height: calc(3.5vw + 13vh);
  font-size: calc(0.7vw + 1.3vh);
  padding: calc(0.9vw + 1.4vh);
}

.level-up-screen .level .level-health {

  height: calc(1.5vw + 6vh);
  width: calc(1.5vw + 6vh);
  position: absolute;
  bottom: calc(0.3vw + 1vh);
  right: calc(0.5vw + 1.5vh);
}

.level-up-screen .level .level-health img {

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.level-up-screen .level .level-health .level-health-value {

  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  justify-content: center;
  text-align: center;
  line-height: calc(1.5vw + 6vh);
  font-size: calc(0.6vw + 2.2vh);
  font-weight: bold;
  color: white;
  text-shadow: 0.3vh 0.3vh 0.2vh #00000080;
}

.level-up-screen .fight-loot-card-skip {

  margin-top: 1.7vh;
}