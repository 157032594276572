.gg-card {

	width: 6.5em;
	height: 9.5em;
	position: relative;
  	filter: drop-shadow(#00000060 0.15rem 0.15rem);
}

.gg-card .card-image-wrapper {

	position: absolute;
	top: 0em;
	left: 0em;
	justify-content: center;
	overflow: hidden;
	transform: translate(0.25em, -0em);
	width: 6.5em;
	height: 9.5em;
}

.gg-card .card-image-wrapper img {

	object-fit: cover;
}

.gg-card .card-mana {

	position: absolute;
	top: -0.35em;
	left: -0.1em;
	width: 1.4em;
	height: 1.4em;
    -webkit-text-stroke-width: 0.04rem;
    -webkit-text-stroke-color: #cccccc;
	z-index: 1;
	color: #edeae4;
	text-shadow: 0.03em 0.03em #70530c80;
	justify-content: center;
	align-items: center;
	font-size: 1.2em;
	line-height: 1.8em;
	pointer-events: none;
}

.gg-card .card-level {

	position: absolute;
	top: -0.35em;
	right: -0.48em;
	width: 1.4em;
	height: 1.4em;
    -webkit-text-stroke-width: 0.04rem;
    -webkit-text-stroke-color: #cccccc;
	z-index: 1;
	color: #edeae4;
	text-shadow: 0.03em 0.03em #70530c80;
	justify-content: center;
	align-items: center;
	font-size: 1.2em;
	line-height: 1.8em;
	pointer-events: none;
}

.gg-card .card-mana-img {

	position: absolute;
	top: -0.6em;
	left: -0.6em;
	width: 2.6em;
	height: 2.6em;
	object-fit: cover;
}

.gg-card .card-frame {

	position: absolute;
	top: -0.6em;
	left: -0.6em;
	width: 8.1em;
	height: 11.1em;
	object-fit: contain;
	filter: drop-shadow(0.1rem 0.1rem #000000A0);
	pointer-events: none;
}

.gg-card .card-name {

	position: absolute;
	text-wrap: wrap;
	bottom: -0.2em;
	left: 0.35em;
	width: 100%;
	height: 4em;
	color: #f5f4f2;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 0.7em;
	line-height: 1em;
	padding: 0 1em;
	box-sizing: border-box;
	background: linear-gradient(transparent, #000000E0 30%, #000000A0);
	border-bottom-left-radius: 30%;
	border-bottom-right-radius: 30%;
}

.gg-card .card-name .card-name-text {

	font-size: 1em;
}

.card-stat {

	position: absolute;
	top: -0.6em;
	right: -0.6em;
	width: 2.6em;
	height: 2.6em;
	object-fit: contain;
	filter: drop-shadow(0.1rem 0.1rem #000000A0);
}

.card-stat-icon {

	position: absolute;
	top: 0;
	left: 0;
	width: 2.6em;
	height: 2.6em;
	object-fit: contain;
	filter: drop-shadow(0.1rem 0.1rem #000000A0);
}

.gg-card .card-atk {

	right: 2em;
}

.card-stat-value {

	position: absolute;
	top: 0;
	left: 0;
	color: white;
	font-size: 2em;
	width: 1.3em;
	justify-content: center;
	text-align: center;
	-webkit-text-fill-color: white#1c2530;
    -webkit-text-stroke-width: 0.08rem;
    -webkit-text-stroke-color: #1c2530;
    font-family: Impact;
}

.gg-card .card-stat-value.card-stat-value-buff {

    -webkit-text-fill-color: #64fff1;
    -webkit-text-stroke-color: #2ccbbb;
	/*-webkit-text-fill-color: #00e51c;
    -webkit-text-stroke-color: #1b351b;*/
}

.gg-card .card-stat-value.card-stat-value-debuff {

	-webkit-text-fill-color: #ff0000;
    -webkit-text-stroke-color: #e9b3b3;
}

.gg-card .card-mana.card-mana-buff {

    -webkit-text-fill-color: #64fff1;
    -webkit-text-stroke-color: #2ccbbb;
	/*-webkit-text-fill-color: #00e51c;
    -webkit-text-stroke-color: #089619;*/
    font-weight: bold;
}

.gg-card .card-mana.card-mana-debuff {

	-webkit-text-fill-color: #ff0000;
    -webkit-text-stroke-color: #970000;
    font-weight: bold;
}

.gg-card .card-image-placeholder {

	position: absolute;
	top: 0.3em;
	left: 0.45em;
	justify-content: center;
	flex-wrap: wrap;
	overflow: hidden;
	transform: translate(0.1em, -0em);
	width: 5.8em;
	height: 9.2em;
}

.gg-card .card-image-placeholder .px, .gg-hero .card-image-placeholder .px {

	width: 25%;
	height: 25%;
}

.gg-card.white-card .card-image-placeholder .px, .gg-card.basic-card .card-image-placeholder .px, .gg-card.champion-1-card .card-image-placeholder .px {

	background: #7d704d;
	animation: white-px linear 4s infinite;
}

.gg-card.breasts-card .card-image-placeholder .px {

	background: #7d4d4d;
	animation: red-px linear 4s infinite;
}

.gg-card.red-card .card-image-placeholder .px, .gg-card.champion-2-card .card-image-placeholder .px {

	background: #7d4d4d;
	animation: red-px linear 4s infinite;
}

.gg-card.blue-card .card-image-placeholder .px, .gg-card.champion-3-card .card-image-placeholder .px {

	background: #4d657d;
	animation: blue-px linear 4s infinite;
}

.gg-card.green-card .card-image-placeholder .px, .gg-card.champion-4-card .card-image-placeholder .px {

	background: #527d4d;
	animation: green-px linear 4s infinite;
}

.gg-card.black-card .card-image-placeholder .px {

	background: #754d7d;
	animation: black-px linear 4s infinite;
}

.gg-card .card-image-placeholder .px:nth-child(1), .gg-hero .card-image-placeholder .px:nth-child(1) { animation-delay: 0 !important }
.gg-card .card-image-placeholder .px:nth-child(2), .gg-hero .card-image-placeholder .px:nth-child(2) { animation-delay: 2.25s !important }
.gg-card .card-image-placeholder .px:nth-child(3), .gg-hero .card-image-placeholder .px:nth-child(3) { animation-delay: 3.5s !important }
.gg-card .card-image-placeholder .px:nth-child(4), .gg-hero .card-image-placeholder .px:nth-child(4) { animation-delay: 1.75s !important }

.gg-card .card-image-placeholder .px:nth-child(5), .gg-hero .card-image-placeholder .px:nth-child(5) { animation-delay: 2s !important }
.gg-card .card-image-placeholder .px:nth-child(6), .gg-hero .card-image-placeholder .px:nth-child(6) { animation-delay: 0.25s !important }
.gg-card .card-image-placeholder .px:nth-child(7), .gg-hero .card-image-placeholder .px:nth-child(7) { animation-delay: 1.5s !important }
.gg-card .card-image-placeholder .px:nth-child(8), .gg-hero .card-image-placeholder .px:nth-child(8) { animation-delay: 3s !important }

.gg-card .card-image-placeholder .px:nth-child(9), .gg-hero .card-image-placeholder .px:nth-child(9) { animation-delay: 3.25s !important }
.gg-card .card-image-placeholder .px:nth-child(10), .gg-hero .card-image-placeholder .px:nth-child(10) { animation-delay: 1.25s !important }
.gg-card .card-image-placeholder .px:nth-child(11), .gg-hero .card-image-placeholder .px:nth-child(11) { animation-delay: 0.5s !important }
.gg-card .card-image-placeholder .px:nth-child(12), .gg-hero .card-image-placeholder .px:nth-child(12) { animation-delay: 2.5s !important }

.gg-card .card-image-placeholder .px:nth-child(13), .gg-hero .card-image-placeholder .px:nth-child(13) { animation-delay: 1s !important }
.gg-card .card-image-placeholder .px:nth-child(14), .gg-hero .card-image-placeholder .px:nth-child(14) { animation-delay: 3s !important }
.gg-card .card-image-placeholder .px:nth-child(15), .gg-hero .card-image-placeholder .px:nth-child(15) { animation-delay: 3.75s !important }
.gg-card .card-image-placeholder .px:nth-child(16), .gg-hero .card-image-placeholder .px:nth-child(16) { animation-delay: 0.75s !important }

@keyframes white-px {

	0% { background: #7d704d }
	20% { background: #c2b89f }
	40% { background: #7d7769 }
	60% { background: #544b37 }
	80% { background: #c4b289 }
	100% { background: #7d704d }
}

@keyframes pink-px {

	0% { background: #a26aa6 }
	20% { background: #bf9fc2 }
	40% { background: #998199 }
	60% { background: #533754 }
	80% { background: #c489c4 }
	100% { background: #a26aa6 }
}

@keyframes red-px {

	0% { background: #7d4d4d }
	20% { background: #c29f9f }
	40% { background: #7d6969 }
	60% { background: #543737 }
	80% { background: #c48989 }
	100% { background: #7d4d4d }
}

@keyframes blue-px {

	0% { background: #4d657d }
	20% { background: #9fb0c1 }
	40% { background: #68737d }
	60% { background: #364553 }
	80% { background: #88a6c3 }
	100% { background: #4d657d }
}

@keyframes green-px {

	0% { background: #527d4d }
	20% { background: #a2c19f }
	40% { background: #6a7d68 }
	60% { background: #395336 }
	80% { background: #8ec388 }
	100% { background: #527d4d }
}

@keyframes black-px {

	0% { background: #754d7d }
	20% { background: #bc9fc1 }
	40% { background: #7a687d }
	60% { background: #4e3653 }
	80% { background: #b988c3 }
	100% { background: #754d7d }
}