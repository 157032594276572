.app * {

  display: flex;
  font-family: MarcellusSC;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.app {

  text-align: center;
  /*background-color: #282c34;background: linear-gradient(to bottom, #282c34, #666a73 200vh);*/
  /* background: linear-gradient(to bottom, #f0feff, white 200vh); */
  background: linear-gradient(to bottom, #0b0c1b, #3f3f4c 200vh);
  /*background-image: url('./background.jpg');
  background-size: cover;
  background-position: center;*/
  min-height: 100vh;
  color: #1c2530;
  font-size: calc(10px + 2vmin);
  font-family: HelveticaNeue-Light, Helvetica, sans-serif, Classic, Alegreya-Regular;
}

.invisible {

  display: none !important;
}

.hidden {

  display: inline-block !important;
  padding: 0 !important;
  margin: 0 !important;
  height: 0 !important;
  width: 0 !important;
  border: 0 !important;
}

.out-of-bounds {

  display: inline-block !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  position: fixed !important;
  left: 200vw !important;
}

a {
  color: #61dafb;
}

ul {

  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  list-style-type: none;
}

h1, h2, h3, h4, h5, h6 {

    font-size: initial;
    margin-block-start: initial;
    margin-block-end: initial;
    margin-inline-start: initial;
    margin-inline-end: initial;
    font-weight: initial;
}

select {

  color: inherit;
  background: #282c34;
  font-family: inherit;
}

.main-page {

  flex-direction: column;
  opacity: 1;
  height: 100vh;
  overflow: hidden;
  animation: main-page-fade-in 0.4s ease-in-out;
}

@keyframes main-page-fade-in {

  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-out {

  opacity: 0;
  transition: 0.4s;
  pointer-events: none;
  animation: none;
}

a {

    color: inherit;
    cursor: inherit;
    text-decoration: inherit;
}

.no-select {
  
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}




.main {

  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.main-page {

  height: 100vh;
}

.headline {

  background-color: #4c515c;
  font-size: 1.4rem;
  padding: 10px;
  text-transform: uppercase;
  justify-content: center;
  color: #a4a4a4;
}

@font-face {
  font-family: MarcellusSC;
  src: url('../fonts/MarcellusSC.ttf');
}

@font-face {
  font-family: Burbank;
  src: url('../fonts/BurbankBigRegular-Medium.otf');
}

@font-face {
  font-family: Impact;
  src: url('../fonts/impact.ttf');
}

@font-face {
  font-family: Rusty;
  src: url('../fonts/rusty-attack-demo.regular.otf');
}

.tooltip.show.bs-tooltip-auto {

  background: #c5cbd1ff;
  border: 2px dashed #2b333b;
  border-radius: 4px;
  font-family: MarcellusSC;
  padding: 0.25rem;
  transition: opacity 0.2s;
  animation: tooltip-appear 0.4s ease-in-out;
}

@keyframes tooltip-appear {

  0% { opacity: 0; }
  80% { opacity: 0; }
  100% { opacity: 1; }
}

.tooltip img {

  height: 0.8em;
  margin-top: 0.2em;
  margin-right: 0.2em;
  margin-left: 0.2em;
}

@media (min-width: 601px) {

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #00000020;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #00000050;
    /*background-color: #706555;*/
    border-radius: 5px;
    transition: background-color 0.2s;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #00000080;
    /*background-color: #575045;*/
  }

  ::-webkit-scrollbar-corner {
    background: #00000020;
  }

  .lightbox-inner ::-webkit-scrollbar-track {

    background: #FFFFFF20;
  }

  .lightbox-inner ::-webkit-scrollbar-thumb {

    background-color: #FFFFFF50;
  }

  .lightbox-inner ::-webkit-scrollbar-thumb:hover {
    
    background-color: #FFFFFF80;
  }

  .lightbox-inner ::-webkit-scrollbar-corner {

    background: #FFFFFF20;
  }
}

@media (max-width: 600px) {

  .pc {
    display: none;
  }

}

@media (min-width: 601px) {

  .mobile {
    display: none;
  }

}