.cardbox-focus-box.lightbox-container .lightbox-inner .lightbox {

	height: 15rem;
	width: 80vw;
	overflow: visible;
	background-color: #1f1f23;
}

.cardbox-focus {

	color: #edeae4;
	flex-direction: column;
	font-size: 1rem;
	position: relative;
	min-height: 100%;
}

.cardbox-focus .cardbox-focus-card {

	position: absolute;
	top: -2vh;
	left: calc(50% - 0.5rem - 0.5vh);
	font-size: calc(1rem + 1vh);
}

.cardbox-focus .cardbox-focus-card .sensuba-hero {

	cursor: pointer;
}

.cardbox-focus h1 {

	text-align: center;
	align-self: center;
	margin-top: 3.5rem;
	font-size: 1.5rem;
	line-height: 1.5rem;
	white-space: nowrap;
}

.cardbox-focus h1.small-name {

	font-size: 1.35rem;
}

.cardbox-focus .card-typing {
	
	font-size: 1.1rem;
	text-align: center;
	align-self: center;
}

.cardbox-focus .cardbox-back-to-parent {
	
	position: absolute;
	bottom: 0;
	text-align: center;
	align-self: center;
	text-decoration: underline #edeae480;
	cursor: pointer;
}

.game-effect {

	text-align: center;
	align-self: center;
	display: block;
}

.champion-focus .champion-quote {

	text-align: center;
	display: block;
	width: 60%;
	align-self: flex-start;
	margin-left: 1.5em;
	margin-right: 1.5em;
}

.champion-focus .champion-quote span {

	text-align: center;
	font-size: 0.8em;
	opacity: 0.8;
	font-style: italic;
	justify-content: center;
}

.champion-focus .game-effect {

	width: 60%;
	align-self: flex-start;
	margin-top: 2.5em;
	margin-left: 1.5em;
	margin-right: 1.5em;
}

.game-effect.small-effect {

	font-size: 0.85em;
}

.game-effect * {

	display: inline;
}

.game-effect .keyword {

	white-space: nowrap;
	font-weight: bold;
	text-decoration: underline dotted #edeae480;
}

.game-effect .keyword.soft {

	font-weight: unset;
}

.game-effect .keyword.unlabelled {

	text-decoration: none;
}

.game-effect .keyword-icon {

	width: 1em;
	height: 1em;
	margin-right: 0.3em;
	margin-bottom: -0.1em;
}

.game-effect .token {

	text-decoration: underline #edeae480;
	cursor: pointer;
}

.game-effect .token.repeat-token {

	cursor: default;
}

.game-effect br {

	content: "";
	display: block;
	margin-top: 0.2rem;
}

.cardbox-focus .cardbox-side {

	position: absolute;
	width: calc(40vw - 5.5rem);
	height: 2.5rem;
	top: 0rem;
	color: #7f7a6a;
	flex-direction: column;
}

.hero-box .cardbox-focus .cardbox-side {

	color: #edeae4a0;
}

.cardbox-focus .cardbox-left {

	left: 0rem;
}

.cardbox-focus .cardbox-right {

	right: 0rem;
}

.cardbox-focus .cardbox-color {

	justify-content: center;
}

.cardbox-focus .cardbox-sound {

	justify-content: center;
	height: 1.5rem;
	width: 1.5rem;
	margin: auto;
	opacity: 0.3;
	cursor: pointer;
}

.hero-box .cardbox-focus .cardbox-sound {

	filter: brightness(1.2) grayscale(1);
}

.cardbox-focus .cardbox-sound.active-sound {

	opacity: 1;
	filter: drop-shadow(#00000060 0.15rem 0.15rem);
}

.hero-box .cardbox-focus .cardbox-sound.active-sound {

	opacity: 1;
	filter: drop-shadow(#00000060 0.15rem 0.15rem) brightness(1.2) grayscale(1);
}

.cardbox-focus .cardbox-sound img {

	width: 1.5rem;
	height: 1.5rem;
	margin-top: 0.25rem;
}

.tooltip.show.bs-tooltip-auto {

	z-index: 1200;
	max-width: 15rem;
	text-align: center;
}

.cardbox-focus .cardbox-abilities {

	font-size: 0.5rem;
	flex-direction: column;
	width: 100%;
	height: 10rem;
}

.cardbox-focus .cardbox-abilities .cardbox-lv {

	width: 100%;
	justify-content: center;
	margin-top: 0.7rem;
	margin-bottom: 0.5rem;
	height: 3.2rem;
	position: relative;
}

.cardbox-focus .cardbox-abilities .cardbox-lv-separator {

	width: 100%;
	height: 0.05rem;
	background: linear-gradient(to right, transparent, #edeae4, transparent);
}

.cardbox-focus .cardbox-abilities .cardbox-lv .sensuba-ability {

	margin: 0 0.55rem;
	cursor: pointer;
}

.cardbox-focus .cardbox-focus-card.cardbox-focus-ability {

	position: absolute;
	top: -3rem;
	left: calc(50% - 3.2rem);
}

.hero-lv-text {
	
	font-family: Rusty;
	color: #d1caae40;
	text-align: center;
	align-items: center;
}

.cardbox-focus .cardbox-lv .hero-lv-text {

	position: absolute;
	left: calc(50% + 4.3rem);
	font-size: 1.5rem;
	height: 3rem;
	justify-content: center;
	width: 3rem;
	top: 0;
}

.cardbox-focus .cardbox-lv-separator + .cardbox-lv .hero-lv-text {

	left: calc(50% + 6.3rem);
}

.cardbox-arrow {

	position: absolute;
	width: 3rem;
	height: 3rem;
	top: 6rem;
	left: calc(10vw - 3.5rem);
	font-size: 4rem;
	line-height: 4rem;
	color: #dec6a2;
	cursor: pointer;
	justify-content: right;
	box-sizing: border-box;
	padding: 0;
	transition: padding 0.2s;
	filter: drop-shadow(0 0 0.8vh black) grayscale(1) brightness(1.2);
}

.hero-box .cardbox-arrow {

	filter: drop-shadow(0 0 0.8vh black) grayscale(1) brightness(1.2);
}

.cardbox-arrow.cardbox-arrow-right {

	left: unset;
	right: calc(10vw - 3.5rem);
	justify-content: left;
}

.cardbox-arrow img {

	height: 80%;
	margin: 10%;
	transform: scaleX(-1);
}

.cardbox-arrow.cardbox-arrow-right img {

	transform: scaleX(1);
}

.cardbox-arrow:hover {

	padding-right: 0.3rem;
}

.cardbox-arrow.cardbox-arrow-right:hover {

	padding-right: unset;
	padding-left: 0.3rem;
}


.cardbox-focus .cardbox-focus-card {

	position: absolute;
	top: calc(-6rem - 10vh);
	left: calc(50% - 3.25em - 0.25rem);
	font-size: calc(1rem + 1vh);
}

.cardbox-focus h1 {

	margin-top: calc(5rem - 0vh);
}

.cardbox-focus .cardbox-focus-card.cardbox-focus-champion {

	left: unset;
	right: 1rem;
	top: calc(50% - 4.75rem - 1em);
}

.cardbox-focus .champion-size-1.cardbox-focus-card.cardbox-focus-champion {

	top: calc(50% - 4.75rem - 1.75em);
}

.cardbox-focus .champion-size-2.cardbox-focus-card.cardbox-focus-champion {

	top: calc(50% - 4.75rem - 2.75em);
}

.cardbox-focus .champion-size-3.cardbox-focus-card.cardbox-focus-champion {

	top: calc(50% - 4.75rem - 4em);
}

.cardbox-focus .champion-size-4.cardbox-focus-card.cardbox-focus-champion {

	top: calc(50% - 4.75rem - 6em);
}

.cardbox-focus .card-power {

	right: 1rem;
	top: calc(50% + 0.25rem);
}

.cardbox-focus .card-health {

	right: 1rem;
	top: calc(50% + 4.75rem);
}

.cardbox-focus .cardbox-focus-card.cardbox-focus-champion + h1 {

	margin-top: 1rem;
}

.cardbox-focus .cardbox-focus-card.cardbox-focus-champion + h1 + .card-typing + .game-effect {

	width: 60%;
	margin-left: -5rem;
	margin-top: 3rem;
}

.cardbox-focus .champion-table {

	padding-left: calc(30% - 4em);
	margin-top: 1em;
}

.cardbox-focus .champion-table .champion-table-category {

	flex-direction: column;
	margin: 0 0.25em;
}

.cardbox-focus .champion-table .champion-table-category-icon {

	width: 2em;
	height: 2em;
}

.cardbox-focus .champion-table .champion-table-category-icon img {

	width: 1.5em;
	height: 1.5em;
	margin: 0.25em;
}

.cardbox-focus .champion-table .champion-table-level {

	width: 1.8em;
	height: 1.8em;
	margin: 0.1em;
	cursor: pointer;
}

.cardbox-focus .champion-table .champion-table-level-text {

	width: 100%;
	height: 100%;
	line-height: 100%;
	text-align: center;
    flex-direction: column;
    justify-content: center;
    font-size: 1.8em;
}

.cardbox-arrow {

	top: calc(50% - 2rem);
}

@media only screen and (min-height: 551px) and (min-width: 601px) {
  
	.cardbox-focus {

		font-size: 1.5rem;
	}

	.cardbox-focus .cardbox-focus-card {

		top: calc(0px - 2rem - 12vh);
    	left: calc(50% - 3.5vh - 3.5rem);
	}

	.cardbox-focus .cardbox-focus-card.cardbox-focus-ability {

		top: -5.5rem;
	    left: calc(50% - 4.55rem);
	}

	.cardbox-focus-box.lightbox-container .lightbox-inner .lightbox {

	    height: 22.5rem;
	    max-width: 40rem;
	}

	.cardbox-focus h1 {

	    margin-top: 8.25rem;
	    font-size: 2.25rem;
	}

	.cardbox-focus h1.small-name {

		font-size: 2.15rem;
	}

	.cardbox-focus .card-typing {
		
		margin-top: 0.5rem;
	}

	.cardbox-focus .cardbox-side {

		width: calc(40vw - 8.25rem);
		max-width: 13rem;
	}

	.cardbox-focus .cardbox-abilities {

		font-size: 0.75rem;
	    height: 15rem;
	}

	.cardbox-focus .cardbox-lv .hero-lv-text {

		left: calc(50% + 6.3rem);
		height: 4.5rem;
		width: 4.5rem;
		font-size: 2rem;
	}

	.cardbox-focus .cardbox-lv-separator + .cardbox-lv .hero-lv-text {

		left: calc(50% + 9.3rem);
	}

	.cardbox-focus .cardbox-abilities .cardbox-lv {

	    margin-top: 1.05rem;
	    margin-bottom: 0.75rem;
	    height: 4.767rem;
	}

	.cardbox-focus-box.lightbox-container .lightbox-inner .lightbox {

		padding-left: 1.5em;
		padding-right: 1.5em;
	}

	.tooltip.show.bs-tooltip-auto {

		max-width: 18rem;
		font-size: 1.2rem;/*
		padding: 0.4rem;*/
	}

	.game-effect.small-effect {

		font-size: inherit;
	}

	.cardbox-arrow {

		width: 4rem;
		height: 4rem;
		top: 10.25rem;
		left: -2rem;
	}

	.cardbox-arrow.cardbox-arrow-right {

		left: unset;
		right: -2rem;
		justify-content: left;
	}

	.cardbox-focus .cardbox-sound {

		height: 2rem;
		width: 2rem;
	}

	.cardbox-focus .cardbox-sound img {

		width: 2rem;
		height: 2rem;
		margin-top: 0.5rem;
	}

	.cardbox-focus .cardbox-focus-card.cardbox-focus-champion {

		right: 3rem;
	}

	.cardbox-focus .champion-size-1.cardbox-focus-card.cardbox-focus-champion {

		right: 1.75rem;
	}

	.cardbox-focus .champion-size-2.cardbox-focus-card.cardbox-focus-champion {

		right: 0.5rem;
	}

	.cardbox-focus .champion-size-3.cardbox-focus-card.cardbox-focus-champion {

		right: -0.25rem;
	}

	.cardbox-focus .champion-size-4.cardbox-focus-card.cardbox-focus-champion {

		right: -1.5rem;
	}

	.cardbox-focus .cardbox-focus-card.cardbox-focus-champion + h1 {

		margin-top: 2rem;
	}

	.cardbox-focus .cardbox-focus-card.cardbox-focus-champion + h1 + .card-typing + .game-effect {

		width: 60%;
		margin-left: -6em;
		margin-top: 2em;
	}

	.cardbox-focus .champion-table {

		padding-left: calc(30% - 4.5em);
		margin-top: 1em;
	}

	.cardbox-focus .champion-table .champion-table-category {

		margin: 0 0.5em;
	}
}

@media only screen and (max-width: 600px) {

	.cardbox-focus .cardbox-focus-card.cardbox-focus-champion {

		font-size: 0.7em;
		top: calc(50% - 2.75rem - 1em);
	}

	.champion-focus .game-effect {

		margin-top: 1em;
		margin-bottom: 1em;
	}
}

@media only screen and (max-height: 550px) {

	.cardbox-focus-box.lightbox-container .lightbox-inner .lightbox {

		max-height: 80vh;
		width: 68vw;
	}

	.cardbox-arrow {

		left: -2rem;
	}

	.cardbox-arrow.cardbox-arrow-right {

    left: unset;
    right: -2rem;
	}

	.cardbox-focus {

		padding-left: calc(1rem + 1vw);
		padding-right: calc(11rem + 1vw);
		/*justify-content: flex-end;*/
	}

	.champion-focus.cardbox-focus {

		justify-content: center;
		margin-top: -1rem;
	}

	.cardbox-focus .cardbox-focus-card.cardbox-focus-champion {

		top: calc(50% - 3.75rem - 1em);
	}

	.cardbox-focus h1 {
        margin-top: 2rem;
    }

	.cardbox-focus .cardbox-focus-card {
		left: unset;
    right: 1rem;
    top: calc(50% - 5.75rem - 1em);
	}
  
  .cardbox-focus .cardbox-focus-card .gg-card {
  	justify-content: center;
    width: 6.5em;
    height: 9.5em;
    position: relative;
    filter: drop-shadow(#00000060 0.15rem 0.15rem);
  }

  .cardbox-arrow {

  	top: calc(50% - 2rem);
  }

  .game-effect {

  	/*min-height: 10rem;*/
  }

  .champion-focus .game-effect {

  	min-height: unset;
  }

  .champion-focus .game-effect {
    width: 80%;
    align-self: flex-start;
    margin-top: 1em;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 0.5em;
	}

	.champion-focus .champion-quote {
    text-align: center;
    display: block;
    width: 80%;
    align-self: flex-start;
    margin-left: 10%;
    margin-right: 10%;
    font-size: 0.9em;
	}
}