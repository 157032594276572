.attack-anim {

	animation: attack 0.5s 1 ease-out;
}

.fastmode .attack-anim {

  animation: attack 0.17s 1 ease-out;
}

@keyframes attack {
  0%   { transform: translateX(0rem); }
  20%   { transform: translateX(4rem); }
  100%   { transform: translateX(0rem); }
}

.game-enemies .attack-anim {

  animation: enemy-attack 0.5s 1 ease-out;
}

.fastmode .game-enemies .attack-anim {

  animation: enemy-attack 0.17s 1 ease-out;
}

@keyframes enemy-attack {
  0%   { transform: translateX(0rem); }
  20%   { transform: translateX(-4rem); }
  100%   { transform: translateX(0rem); }
}