.story-page main {

	height: 100vh;
	width: 100vw;
}

.story-background {

	height: 100vh;
	width: 100vw;
	position: absolute;
	top: 0;
	left: 0;
}

.story-background.locked {

	filter: grayscale(1);
}

.story-background img {

	height: 100%;
	width: 100%;
	object-fit: cover;
	object-position: left;
}

.story-champion {

	width: calc(10rem + 26vw);
	background: #FFFFFFA0;
	box-shadow: 0 0 1rem 1rem #FFFFFFA0;
	z-index: 1;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: calc(28vw - 8rem);
	flex-direction: column;
	align-items: center;
	padding: 1.5rem;
	box-sizing: border-box;
}

.story-champion .story-champion-name {

	font-weight: bold;
	margin-bottom: 0.5rem;
}

.story-champion .story-champion-effect {

	margin-bottom: 0.5rem;
}

.story-champion .story-champion-quote {

	font-style: italic;
	opacity: 0.8;
	font-size: 0.8em;
}

.story-champion .story-champion-quote-wrapper {

	flex-direction: column;
	margin-bottom: 0.5rem;
}

.story-champion .story-champion-quote-wrapper a {

}

.story-champion .story-embark {

	font-style: italic;
	cursor: pointer;
	text-decoration: underline dotted;
	transition: color 0.2s;
}

.story-champion .story-embark:hover {

	color: #56677c;
}

.story-champion .game-effect .keyword {

	text-decoration: none;
}

.story-champion .cardbox-arrow {

	top: calc(50% - 2rem);
	left: -8vw;
	filter: contrast(0.5) brightness(2) drop-shadow(0 0 3px #000000C0);
}

.story-champion .cardbox-arrow.cardbox-arrow-right {

	left: unset;
	right: -8vw;
}

.story-patreon {

	flex-direction: column;
	width: 100%;
}

.story-patreon * {

	width: 100%;
	justify-content: center;
}

.story-patreon a {

	cursor: pointer;
	font-weight: bold;
	font-size: 0.8em;
	color: #042a59;
	text-decoration: underline dotted;
	transition: color 0.2s;
}

.story-patreon a:hover {

	color: #1d4b84;
}

.difficulty-switch {

	/*margin-top: 0.7rem;
	border-radius: 0.5rem;
	overflow: hidden;
	border: 2px solid whitesmoke;*/
	margin-top: 0.7rem;
}

.difficulty-switch .difficulty-option {

	/*min-width: 8rem;
	height: 1.8rem;
	line-height: 1.8rem;
	text-align: center;
	justify-content: center;
	transition: background 0.2s, color 0.2s;
	cursor: pointer;
	opacity: 0.6;*/
	height: 2rem;
	width: 2rem;
	padding: 0.05rem;
	cursor: pointer;
	border: 2px solid transparent;
	opacity: 0.6;
	margin: 0 0.3rem;
	box-sizing: border-box;
	background: transparent;
	border-radius: 50%;
}

.difficulty-switch .difficulty-option.active {

	/*font-weight: bold;
	color: #edeae4;
	cursor: default;
	opacity: 1;*/
	cursor: default;
	opacity: 1;
	border: 2px solid black;
}

.difficulty-switch .difficulty-option.zen-option.active {

	border: 2px solid #0d5300;
	background: linear-gradient(to bottom, #0d530080, #25b70a80);
}

.difficulty-switch .difficulty-option.casual-option.active {

	border: 2px solid #002a72;
	background: linear-gradient(to bottom, #002a7280, #0a8db780);
}

.difficulty-switch .difficulty-option.challenge-option.active {

	border: 2px solid #531600;
	background: linear-gradient(to bottom, #a82c0080, #b0720080);
}

.difficulty-switch .difficulty-option.hardcore-option.active {

	border: 2px solid black;
	background: linear-gradient(to bottom, #32000080, #71000080);
}

/*
.difficulty-switch .casual-option {

	background: linear-gradient(to bottom, #6888c0, #71c9e6);
}

.difficulty-switch .casual-option.active {

	background: linear-gradient(to bottom, #002a72, #0a8db7);
}

.difficulty-switch .challenge-option {
	
	background: linear-gradient(to bottom, #eb754b, #e3ae4e);
}

.difficulty-switch .challenge-option.active {
	
	background: linear-gradient(to bottom, #a82c00, #b07200);
}*/

.story-floor-choice.locked .main-button {
    cursor: default;
    background: linear-gradient(to bottom, #5a5a5a, #999999);
    color: #505050;
}

.story-floor-choice.locked .main-button:hover {

}

.story-floor-choice.locked .main-button::after {

	content: none;
}

.story-floor-choice .main-button-text {

	justify-content: space-between;
	width: 13rem;
}

.story-floor-choice-index {

}

.story-floor-choice-cost {

	
}

.story-floor-choice img {

	margin-left: 0.2em;
	margin-top: 0.1em;
	height: 1em;
	width: 1em;
}

@media only screen and (max-height: 550px) {

    .story-page .story-champion {

        width: calc(14rem + 26vw);
    }

    .story-champion .game-effect {

        min-height: unset;
    }
}

@media only screen and (max-width: 600px) {

    .story-champion {
	    width: 100%;
	    background: #FFFFFFA0;
	    box-shadow: 0 0 1rem 1rem #FFFFFFA0;
	    z-index: 1;
	    position: absolute;
	    top: 65%;
	    transform: translateY(-50%);
	    right: unset;
	    flex-direction: column;
	    align-items: center;
	    padding: 1.5rem;
	    box-sizing: border-box;
	}

    .story-champion .cardbox-arrow {
		top: 0.65rem;
    	left: calc(40vw - 3.5rem);
	}

	.story-champion .cardbox-arrow.cardbox-arrow-right {
	    left: unset;
	    right: calc(40vw - 3.5rem);
	    justify-content: left;
	}

	.story-background img {

		object-position: calc(10% + 20vw);
	}
}