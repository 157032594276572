.game-hand {

	width: 100%;
	justify-content: space-around;
	height: calc(20vh + 4vw);
	box-sizing: border-box;
	pointer-events: none;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;
}

.game-hand-card {

	top: 0vw;
    z-index: 1;
    width: calc(13.333vh + 2.666vw);
    height: calc(10vh + 8vw);
	pointer-events: all;
	transition: 0.4s left, 0.4s top, 0.4s transform, 0.4s opacity, 0.2s filter;
	margin: 1vw;
	font-size: calc(2vh + 0.4vw);/*1.2vw;*/
	position: absolute;
	cursor: pointer;
}

.game-hand-card .gg-card {

	transition: box-shadow 0.5s ease-out;
}

.active-halo .gg-card {

	box-shadow: 0 0 0.8vw 0.8vw #518bff;
	animation: active-halo-color 1.3s alternate infinite ease-in-out;
}

@keyframes active-halo-color {
	from { box-shadow: 0 0 0.9vw 0.9vw #518bff }
	to { box-shadow: 0 0 0.7vw 0.5vw #518bff }
}

.selected-halo .gg-card {

	box-shadow: 0 0 0.8vw 0.8vw red;
	animation: selected-halo-color 1.3s alternate infinite ease-in-out;
}

@keyframes selected-halo-color {
	from { box-shadow: 0 0 0.9vw 0.9vw red }
	to { box-shadow: 0 0 0.7vw 0.5vw red }
}

.game-hand-card .game-card-wrapper {

	animation: game-card-enter 0.5s ease-out;
	position: relative;
}

.fastmode .game-hand-card .game-card-wrapper {

	animation-duration: 0.2s;
}

.game-hand-card .game-card-wrapper .gg-card {

	transition: 0.4s transform;
}

.game-hand-card:hover .game-card-wrapper .gg-card, .game-hand-card:hover .game-card-wrapper .card-volatile {

    transform: translateY(-3vw);
}

@keyframes game-card-enter {
	from { transform: translateY(8em); }
	to { transform: translateY(0); }
}

.game-hand-card:hover {

    z-index: 2;
}



.card-focus {

	box-shadow: 0 0 0.2em 0.2em red;
}

.card-locked {

	filter: grayscale(1) brightness(0.5);
}

.game-card-info-inner {

	position: absolute;
	left: 2vw;
	bottom: 2vw;
	width: 16vw;
	height: calc(6vw + 10vh);
	background: #1f1f23;
	border-radius: 2px;
	padding: 1.5vw;
	padding-top: 1vw;
	box-sizing: border-box;
	box-shadow: 0px 0px 4px 0px rgba(100, 100, 100, 0.35);
	color: #edeae4;
	text-align: inherit;
	align-self: inherit;
	display: flex;
	font-size: calc(1vw + 1vh);
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.game-card-info-type {

	font-size: 0.7em;
	opacity: 0.6;
	justify-content: center;
}

.game-card-info-effect {

	text-align: inherit;
	align-self: inherit;
}

.game-hand .game-card-info .game-effect .keyword, .game-card-info.game-effect .token {

	text-decoration: none;
}

.game-hand .game-card-info .game-effect .damage-effect {

	color: #ff8181;
}

.game-hand .game-card-info .game-effect .block-effect {

	color: #8581ff;
}

.game-hand .game-card-info .game-effect .othervalue-effect {

	color: #ffdf81;
}

.game-card-info .game-card-info-name {

	justify-content: center;
	margin-bottom: 0.5rem;
}

.game-card-info .game-card-info-effect.text-long {
	
	font-size: 0.8em;
}

.game-card-info .game-card-info-effect.text-verylong {
	
	font-size: 0.7em;
}

.game-card-info .game-card-info-effect {
	
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 0.9em;
}

.game-hand .game-card-info .token {

	pointer-events: all;
}

.game-hand-card .card-volatile {

    position: absolute;
    top: -0.5em;
    left: -0.3em;
    width: 7.5em;
    height: 10.8em;
    overflow: hidden;
    animation: volatile-color 7s infinite linear, volatile-border 4s infinite alternate;
	transition: 0.4s transform;
	border-radius: 0.85em;
	border-bottom-left-radius: 3.75em 1.2em;
	border-bottom-right-radius: 3.75em 1.2em;
}

@keyframes volatile-color {
  0% { background: rgba(175, 255, 255, 0.25); }
  20% { background: rgba(220, 255, 255, 0.4); }
  50% { background: rgba(175, 255, 255, 0.25); }
  80% { background: rgba(130, 255, 255, 0.4); }
  100% { background: rgba(175, 255, 255, 0.25); }
}

@keyframes volatile-border {
  from { box-shadow: inset 0 0 0.7em 0.35em rgba(64, 0, 128, 0.8), 0 0 0.6em 0.3em rgba(64, 0, 128, 0.8); }
  to { box-shadow: inset 0 0 1em 0.5em rgba(0, 0, 0, 1), 0 0 0.7em 0.35em rgba(0, 0, 0, 1); }
}

@media only screen and (max-height: 550px) {

	.game-hand-card:hover .game-card-wrapper .gg-card, .game-hand-card:hover .game-card-wrapper .card-volatile {

	    transform: none;
	}

	.selected-halo .gg-card {

		transform: translateY(-3vw) !important;
	}

	.game-card-info-inner {

		left: 1vw;
		bottom: 1vw;
		width: 17vw;
		height: calc(7vw + 10vh);
	}
}