.card-shuffle-deck.card-shuffle-deck-anim {

  animation: card-shuffle-deck-opacity 0.5s ease-in-out 1, card-shuffle-deck-shift 0.5s ease-out 1;
}

@keyframes card-shuffle-deck-opacity {
  0% { opacity: 0 }
  30% { opacity: 1 }
  70% { opacity: 1 }
  100% { opacity: 0 }
}

@keyframes card-shuffle-deck-shift {
  from { top: -10vh; left: calc(-0.4rem - 0.6vw - 0.4vh); height: calc(1.6rem + 2.4vw + 1.6vh); width: calc(1.6rem + 2.4vw + 1.6vh); }
  to { top: calc(0.2rem + 0.3vw + 0.2vh); left: calc(0.15rem + 0.225vw + 0.15vh); height: calc(0.6rem + 0.9vw + 0.6vh); width: calc(0.6rem + 0.9vw + 0.6vh); }
}