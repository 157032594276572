.nav-socials {

	width: 2rem;
	height: 2rem;
	cursor: pointer;
	top: 1rem;
	right: 4rem;
	position: absolute;
}

.nav-options .lightbox-container {

	cursor: default;
}

.socials-icon {

	width: 2rem;
	height: 2rem;
	background: url('./socials.png');
	background-size: 100%;
	background-repeat: no-repeat;
	filter: brightness(1.0) drop-shadow(0.2rem 0.2rem #000000A0);
	transition: filter 0.2s, transform 0.4s;
	transform: rotateZ(0deg);
}

.main-page.light .socials-icon {

	filter: brightness(0.2);
}

.socials-box .main-button {

	min-width: 12rem;
}