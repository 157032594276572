.status-anim {

}

.game-entity .status-anim .game-entity-afflict-status-text {

  animation: status-text-opacity 0.8s 1 ease-in-out;
}

@keyframes status-text-opacity {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

.game-entity .status-anim .game-entity-afflict-status-img {

  animation: status-img-opacity 0.8s 1 ease-in-out, status-img-size 0.8s 1 ease-in-out;
}

@keyframes status-img-opacity {
  0% { opacity: 0; }
  10% { opacity: 0.9; }
  60% { opacity: 0.9; }
  100% { opacity: 0; }
}

@keyframes status-img-size {
  from { top: 0; left: 0; height: 100%; width: 100%; }
  to { top: -50%; left: -50%; height: 200%; width: 200%; }
}