.add-to-hand-anim {

	animation: add-to-hand ease-out 0.3s;
}

.fastmode .add-to-hand-anim {

  animation-duration: 0.15s;
}

@keyframes add-to-hand {
  0%   { transform: translateY(8rem); }
  100%   { transform: translateY(0rem); }
}

@media only screen and (max-width: 600px) {


  @keyframes add-to-hand {
    0%   { top: 0vw; }
    100%   { top: 0vw; }
  }
}