.card-dropper .lightbox-container {

  background: #000000D0;
  z-index: 20;
}

.scene-over .card-dropper .lightbox-container {

    z-index: 22;
}

.card-dropper .lightbox-container.cardbox-focus-box {

  z-index: 25;
}

.lightbox-container.cardbox-focus-box .lightbox {

  transform: translateY(0.6rem);
}

.card-dropper-inner {

  height: 90vh;
  width: 100%;
  justify-content: space-evenly;
}

.card-dropper .game-card-info {

  position: relative;
  left: unset;
  bottom: unset;
  flex-direction: column;
  background: #1f1f23;
  border-radius: 2px;
  padding: calc(1vw + 1.5vh);
  padding-top: calc(0.66vw + 1vh);
  box-sizing: border-box;
  width: 100%;
  height: calc(5vw + 15vh);
  box-shadow: 0px 0px 4px 0px rgba(100, 100, 100, 0.35);
  color: #edeae4;
  justify-content: center;
  font-size: calc(0.9vw + 1.4vh);
}

.card-dropper-info {

  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(10vw + 20vh);
}

.card-dropper-text {

  margin-bottom: 8vh;
  justify-content: center;
  font-size: calc(1.5vw + 3vh);
  font-weight: bold;
  text-shadow: 0.15rem 0.15rem 0.1rem #00000080;
  color: #edeae4;
}

.card-dropper-options {

  width: calc(80vw - 20vh);
  height: 100%;
  flex-flow: wrap;
  font-size: 1.1rem;
  justify-content: center;
  transform: translateY(6vh);
}

.card-dropper-option {

  margin: 0 0.8vw;
  cursor: pointer;
  font-size: calc(0.8vh + 1vw);
  width: 6.5em;
  height: 9.5em;
}

.card-dropper-option .card-dropper-option-inner {

  transition: margin 0.2s, font-size 0.2s;
}

.card-dropper-option:hover .card-dropper-option-inner {

  font-size: 1.1em;
  margin-top: -0.31em;
  margin-left: -0.31em;
}