.scene {

	align-items: center;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
	position: relative;
	transition: filter 2s;
}

.cutscene {

	z-index: 21;
}

.fight-scene::before {

	content: "";
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	background: #00000000;
	pointer-events: none;
	transition: background 0.4s ease-in-out;
}

.fight-scene.handchoice::before {

	background: #000000D0;
}

.scene .scene-background {

	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
}

.scene .scene-background img {

	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.scene .scene-background .background-particle {

	display: none;
}

.scene .scene-background::before {

    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 21;
    background: #00000000;
    pointer-events: none;
    transition: background 0.4s ease-in-out;
}

.scene.scene-drop-item .scene-background::before, .scene.scene-drop-relic .scene-background::before {
	
    background: #000000D0;
    pointer-events: all;
}

.scene.scene-drop-item .scene-item-list.scene-relic-list, .scene.scene-drop-relic .scene-item-list:not(.scene-relic-list) {
	
    z-index: 20;
}

.scene .game-hand {

	position: absolute;
	bottom: 0;
	right: 0vw;
	width: 100vw;
	z-index: 2;
}

.scene .game-champion {

	position: absolute;
	bottom: 32vh;
	left: 0vw;
	width: 40vw;
	z-index: 18;
}

.scene .game-enemies {

	position: absolute;
	bottom: 32vh;
	right: 0vw;
	width: 60vw;
	z-index: 18;
}

.champion-difficulty {

	width: 4vh;
	height: 4vh;
	position: absolute;
	top: 2.75vh;
  left: calc(50% - 13vw - 4vh);
	filter: drop-shadow(2px 2px);
	z-index: 21;
}

.exp-bar-wrapper {

  height: 1.5vh;
  width: 24vw;
  position: absolute;
  top: 4vh;
  left: calc(50% - 12vw);
  z-index: 21;
}

.exp-bar-text {

  position: absolute;
  width: 24vw;
  justify-content: center;
  color: white;
  font-size: 4vh;
  top: -1.75vh;
  text-shadow: 0.3vh 0.3vh 0.2vh #00000080;
}

.exp-bar {

  height: 1.5vh;
  width: 24vw;
  background: #3e3a0e82;
  border-radius: 0.375vh;
  overflow: hidden;
  box-shadow: 0.3vh 0.3vh 0.2vh 0px #00000080;
}

.exp-bar .exp-bar-fill {

  height: 100%;
  background: #938700;
}

.levelup .exp-bar .exp-bar-fill {

  background: #009383;
}

.mana-count-wrapper {

	position: absolute;
	height: calc(4vh + 4vw);
	width: calc(4vh + 4vw);
	bottom: calc(22vh - 2vw);
	left: 19vw;
}

.mana-count-wrapper img {
		
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		filter: drop-shadow(0.5vh 0.5vh);
}

.mana-count-wrapper .mana-count {
	
		height: 90%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		justify-content: center;
		align-items: center;
		font-size: calc(3vh + 3vw);
		font-weight: bold;
		color: white;
  	text-shadow: 0.3vh 0.3vh 0.2vh #00000080;
}

.fight-end-turn {

	position: absolute;
	bottom: calc(1vw + 1vh);
	right: calc(13vw - 6rem);
	font-size: calc(0.8vw + 0.8vh);
}

.locations-info {

	position: absolute;
	bottom: calc(6vw + 4vh);
	right: calc(13vw - 5.5rem);
	height: calc(1rem + 1.5vw + 1vh);
	cursor: pointer;
}

.location-info {

	height: 100%;
	width: calc(4vw + 4vh);
	position: relative;
}

.location-info .location-icon {

	height: 90%;
	margin-top: 5%;
}

.location-count {

	font-size: calc(1.5vw + 1vh);
	height: 100%;
	align-items: center;
	margin-left: 0.5vw;
}

.card-shuffle-deck {

	position: absolute;
	height: calc(1.6rem + 2.4vw + 1.6vh);
	width: calc(1.6rem + 2.4vw + 1.6vh);
	overflow: hidden;
	border-radius: 50%;
	border: solid 1px black;
	top: -10vh;
	left: calc(-0.4rem - 0.6vw - 0.4vh);
	opacity: 0;
	pointer-events: none;
}

.card-shuffle-deck img {

	object-fit: cover;
	width: 100%;
	object-position: 0% 25%;;
}

.champion-info {

	position: absolute;
	top: 2.75vh;
	left: calc(4vw + 6vh);
	height: 4vh;
	width: 30vw;
	justify-content: center;
}

.champion-info + .champion-info {

	left: unset;
	right: calc(4vw + 2vh);
}

.champion-stat {

	height: 4vh;
	width: 16vh;
	font-size: 4vh;
	position: relative;
  z-index: 21;
}

.champion-stat#champion-level {

	width: 12vh;
}

.champion-stat#champion-health {

	width: 18vh;
}

.champion-stat img {
		
		height: 4vh;
		width: 4vh;
		position: absolute;
		top: 0;
		left: 0;
		filter: drop-shadow(2px 2px);
}

.champion-stat .stat-count {
	
		height: 4vh;
		width: 8vh;
		margin-left: 1vh;
		position: absolute;
		top: 0;
		left: 4vh;
		align-items: center;
		white-space: nowrap;
		font-size: 4vh;
		font-weight: bold;
		color: white;
  	text-shadow: 0.15rem 0.15rem 0.1rem #00000080;
}

.deck-viewer-open-wrapper {
	
		width: 2rem;
    height: 2rem;
    right: 4rem;
    top: 1rem;
		position: absolute;
 		z-index: 22;
}

.deck-viewer-open {
	
		height: 3rem;
		width: 3rem;
		position: absolute;
		top: -0.5rem;
		left: -0.5rem;
		cursor: pointer;
}

.deck-viewer-open-wrapper.open .deck-viewer-open {
	
		cursor: default;
}

.deck-viewer-open img {

		filter: drop-shadow(2px 2px);
		transition: transform 0.2s ease-in-out;
}

.deck-viewer-open:hover img {
	
		transform: rotateZ(15deg);
}

.deck-viewer-open-wrapper.open .deck-viewer-open img {
	
		transform: rotateZ(15deg);
}

.scene-item-list {

	  position: absolute;
    top: 9vh;
    z-index: 21;
    left: calc(2.75vw + 12vh);
    height: 6vh;
    width: 30vw;
    justify-content: flex-start;
}

.scene-item-list.scene-relic-list {

    left: 2vw;
}

.scene-item-list .scene-item {

    height: 6vh;
    width: 6vh;
    position: relative;
}

.scene-item-list .scene-item .inner-item {

		filter: drop-shadow(2px 2px);
}

.scene-item-list .scene-item img {

		position: absolute;
    height: 6vh;
    width: 6vh;
    top: 0;
    left: 0;
    transition: all 0.15s ease-in-out;
}

.scene-item-list .scene-item.usable {

		cursor: pointer;
}

.scene-item-list .scene-item.usable:hover img, .scene-item-list.scene-relic-list .scene-item.inner-item img {

    height: 7vh;
    width: 7vh;
    top: -0.5vh;
    left: -0.5vh;
}

.scene-item-list.scene-relic-list .scene-item.usable:hover img {

    height: 8vh;
    width: 8vh;
    top: -1vh;
    left: -1vh;
}

.scene-item-list .item-placeholder {

	opacity: 0.4;
}

.scene-item-list img.item-placeholder {

	position: absolute;
    height: 6vh;
    width: 6vh;
    top: 0vh;
    left: 0vh;
}

.inventory-drop-text {

	position: absolute;
	top: 16vh;
	left: 3vw;
	color: white;
	animation: appear 0.4s ease-in-out;
	z-index: 21;
}

@keyframes appear {
	from { opacity:0 }
	to { opacity:1 }
}

.scene-quest {

	  position: absolute;
    top: 10vh;
    z-index: 18;
    right: 2vw;
    width: 8em;
    padding: 1.5vh;
	  background: #c5cbd1b0;
	  border: 2px dashed #2b333b;
    flex-direction: column;
    font-size: 3vh;
}

.scene-quest.active {

    background: linear-gradient(90deg, #da2a83b0, #0000FFB0, #da2a83b0);
    background-size: 200%;
    animation: quest-background 3s linear infinite;
    color: #edeae4;
    border-color: #edeae4;
    cursor: pointer;
}

@keyframes quest-background {
	from { background-position-x: 0 }
	to { background-position-x: 200% }
}

.scene-quest h2 {

	  text-align: center;
	  justify-content: center;
	  font-weight: bold;
	  margin-bottom: 0.5vh;
    font-size: 3vh;
}

.scene-quest .quest-text {

	  line-height: 0.9em;
	  text-align: center;
	  justify-content: center;
}

.game-over {

	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: #120012C0;
	z-index: 21;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #edeae4;
	animation: game-over-enter-opacity 2s ease-in-out;
}

.game-over.game-over-win {

	background: #001212C0;
}

@keyframes game-over-enter-opacity {
	from { opacity: 0 }
	to { opacity: 1 }
}

.game-over .game-over-quote {

	height: calc(2rem + 12vh);
	margin-bottom: calc(0.5rem + 2vh);
}

.game-over .game-over-quote div {

	flex-direction: column;
	align-items: center;
}

.game-over .game-over-quote div span {

	opacity: 0;
	animation: game-over-enter-opacity 2.5s ease-in-out;
	animation-fill-mode: forwards;
	font-size: 0.85em;
	color: #a3a2b5;
	font-weight: bold;
}

.game-over .game-over-quote div span:nth-child(1) {

	animation-delay: 1.2s;
	font-size: 1em;
	color: inherit;
	font-weight: inherit;
	margin-bottom: 0.3em;
}

.game-over .game-over-quote div span:nth-child(2) {

	animation-delay: 2.7s;
}

.game-over .game-over-quote div span:nth-child(3) {

	animation-delay: 4.2s;
}

.game-over .game-over-quote div span:nth-child(4) {

	animation-delay: 5.7s;
}

.game-over h2 {

	font-size: 3rem;
	margin-bottom: calc(0.5rem + 4vh);
}

.game-over .game-over-recap {

	flex-direction: column;
	margin-bottom: calc(0.5rem + 6vh);
}

.game-over .game-over-champion {

	justify-content: center;
	font-size: 1.6rem;
	font-weight: bold;
}

.game-over .game-over-stats {

	flex-direction: column;

}

.game-over .game-over-stat {

	width: 16rem;
}

.game-over .game-over-stat-name {

	width: 10rem;
	text-align: left;
	justify-content: left;
}

.game-over .game-over-stat-value {

	width: 6rem;
	text-align: right;
	justify-content: right;
}

.game-over .game-over-runes {

	align-content: center;
	justify-content: center;
	color: #a3a2b5;
}

.game-over .game-over-runes img {

	margin-left: 0.3em;
	height: 1.3em;
	width: 1.3em;
}

.scene-unlock-inner {

	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: absolute;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background: #001212C0;
}

.scene-unlock-text {

	font-size: 2rem;
	margin-bottom: calc(0.5rem + 4vh);
	color: white;
}

.scene-unlock-img {

	height: 50vh;
	width: 50vh;
	position: relative;
}

.scene-unlock-img img {

	object-fit: contain;
	height: 50vh;
	width: 50vh;
}

.unlock-level .scene-unlock-img img {

	filter: brightness(0);
}

.unlock-level .scene-unlock-img::before {

	content: "";
	height: 40vh;
	width: 20vh;
	position: absolute;
	top: 5vh;
	left: 15vh;
	border-radius: 50%;
	background: #FFFFFFA0;
	box-shadow: 0 0 4rem 1rem white;
}

.unlock-stage .scene-unlock-img img {

	object-fit: cover;
	border-radius: 50%;
	height: 50vh;
	width: 50vh;
	z-index: 1;
}

.unlock-stage .scene-unlock-img::before {

	content: "";
	height: 40vh;
	width: 40vh;
	border-radius: 50%;
	position: absolute;
	top: 5vh;
	left: 5vh;
	box-shadow: 0 0 4rem 1rem white;
}

.time-down.lightbox-container {

  background: #000000D0;
  display: none;
  opacity: 0;
}

.time-down.lightbox-container .time-down-img {

	position: absolute;
	height: 30vh;
	width: 30vh;
	top: calc(50% - 15vh);
	left: calc(50% - 15vh);
}

.shop-items-wrapper {

	position: absolute;
	bottom: 33vh;
	right: calc(45vw - 32vh);
  width: calc(10vw + 50vh);
  height: 32vh;
  justify-content: center;
  align-items: center;
	z-index: 2;
}

.shop-items {

	position: absolute;
	top: 0;
	left: 0;
  width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, auto);
}

.shop-items.items-3 {

	top: 8vh;
}

.shop-items .shop-article {

	position: relative;
	height: 12vh;
	width: 12vh;
	margin: 2vh 2vh;
}

.shop-items .shop-item-wrapper {

	position: relative;
	height: 12vh;
	width: 12vh;
	cursor: pointer;
}

.shop-items .shop-item {

	position: absolute;
	top: 1vh;
	left: 2vh;
	height: 8vh;
	width: 8vh;
	justify-content: center;
	transition: all 0.1s ease-in-out;
}

.shop-items .shop-item-wrapper:hover .shop-item {

	top: 0vh;
	left: 1vh;
	height: 10vh;
	width: 10vh;
}

.shop-items .shop-item-wrapper:hover .gg-card {

	font-size: 1.25vh;
}

.shop-items .shop-item img {

	filter: drop-shadow(2px 2px);
}

.shop-items .shop-price {

	position: absolute;
	top: 7.5vh;
	left: 0vh;
	height: 4vh;
	line-height: 4vh;
	font-size: 4vh;
	color: white;
	width: 12vh;
	text-align: center;
	justify-content: center;
	filter: drop-shadow(2px 2px black);
	transition: all 0.1s ease-in-out;
}

.shop-items .shop-article:before {

	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #00000080;
	border-radius: 50%;
	z-index: -1;
}

.shop-items .shop-item-wrapper:hover .shop-price {

	top: 8vh;
	left: 0vh;
	height: 5vh;
	line-height: 5vh;
	font-size: 5vh;
}

.shop-items .shop-price img {

	margin-right: 0.5vh;
}

.shop-items .gg-card {

	font-size: 1vh;
	transition: font-size 0.1s ease-in-out;
}

.shop-items .shop-article-info {

	position: absolute;
	height: 6vh;
	width: 6vh;
	top: -1vh;
	left: calc(1.5vw + 7.5vh);
	cursor: pointer;
}

.shop-items .shop-article-info img {

	position: absolute;
	height: 6vh;
	width: 6vh;
	top: 0vh;
	left: 0vh;
	transition: all 0.1s ease-in-out;
	cursor: pointer;
	filter: drop-shadow(2px 2px black);
}

.shop-items .shop-article-info:hover img {

	height: 7vh;
	width: 7vh;
	top: -0.5vh;
	left: -0.5vh;
}

.shop-items .shop-article-watermark {

	position: absolute;
	height: 10vh;
	width: 10vh;
	top: 1vh;
	left: 1vh;
	opacity: 0.5;
	z-index: -1;
}

.scene .game-merchant {

	position: absolute;
	bottom: 35vh;
	right: 0vw;
	width: 60vw;
	z-index: 1;
}

.scene .game-merchant .game-entity {

	margin-left: calc(5vh + 30vw);
}

.scene .shop-sign {

	position: absolute;
	bottom: 4vh;
	right: calc(45vw - 20vh);
	height: 22vh;
	width: calc(40vh + 22vw);
	border-radius: 2vh;
	z-index: 2;
	padding: 4vh;
	box-sizing: border-box;
	flex-direction: column;
	border: 2px solid #2b333b;
	background: linear-gradient(to bottom, #97a4b0, #c5cbd1);
	font-size: calc(2.6vh + 0.35vw);
	justify-content: space-around;
}

.scene .shop-name {

	width: 100%;
	justify-content: center;
	font-weight: bold;
}

.scene .shop-description {

	width: 100%;
	justify-content: center;
}






.scene .scene-background.background-love {

	background: radial-gradient(white, pink);
}

.scene .scene-background.background-love2 {

	background: radial-gradient(white, purple);
}

.scene .scene-background.background-deckbuilder {

	background: radial-gradient(lightblue, royalblue);
}

.scene .scene-background.background-love img, .scene .scene-background.background-love2 img, .scene .scene-background.background-deckbuilder img {

	display: none;
}

.scene .scene-background.background-love .background-particle, .scene .scene-background.background-love2 .background-particle {

	position: absolute;
	height: 60vh;
	width: 60vh;
	opacity: 0.2;
	background: url(https://i.imgur.com/cTvihD6.png);
	background-size: 100%;
	background-repeat: no-repeat;
	display: inherit;
	animation: love-particle-shift 24s linear infinite;
}

.scene .scene-background.background-love .background-particle:nth-child(2), .scene .scene-background.background-love2 .background-particle:nth-child(2) {

	left: calc(5vw - 30vh);
	animation-delay: 0;
	animation-duration: 23s;
}

.scene .scene-background.background-love .background-particle:nth-child(3), .scene .scene-background.background-love2 .background-particle:nth-child(3) {

	left: calc(35vw - 30vh);
	animation-delay: -12s;
	animation-duration: 22s;
}

.scene .scene-background.background-love .background-particle:nth-child(4), .scene .scene-background.background-love2 .background-particle:nth-child(4) {

	left: calc(65vw - 30vh);
	animation-delay: -2s;
	animation-duration: 21s;
}

.scene .scene-background.background-love .background-particle:nth-child(5), .scene .scene-background.background-love2 .background-particle:nth-child(5) {

	left: calc(95vw - 30vh);
	animation-delay: -18s;
	animation-duration: 24s;
}

.scene .scene-background.background-love2 .background-particle {

	filter: hue-rotate(-60deg);
}

@keyframes love-particle-shift {

	from { top: 100vh }
	to { top: -60vh }
}

.scene .scene-background.shop-background:after {

	background: #000000A0;
  box-shadow: inset 0 0 8vh 1rem black;
}

.tooltip-button {

	background: #5f5f7f80;
	border-radius: 2px;
	border: solid 1px #00000080;
	font-weight: bold;
	transition: 0.15s background, 0.15s border-color;
}

.tooltip-button:active {

	background: #5f5f7f;
	border-color: #000000;
}

@media only screen and (max-height: 550px) {

	.scene-item-list {

		top: 14vh;
	}

	.scene-item-list .scene-item {

	    height: 8vh;
	    width: 8vh;
	    position: relative;
	}

	.scene-item-list {

	    left: calc(2.75vw + 16vh);
	}

	.scene-item-list img.item-placeholder {
	    height: 8vh;
	    width: 8vh;
	}

	.scene-item-list .scene-item img {

	    height: 8vh;
	    width: 8vh;
	}

	.scene-item-list .scene-item.usable:hover img, .scene-item-list.scene-relic-list .scene-item.inner-item img {

	    height: 9vh;
	    width: 9vh;
	    top: -0.5vh;
	    left: -0.5vh;
	}

	.scene-item-list.scene-relic-list .scene-item.usable:hover img {

	    height: 10vh;
	    width: 10vh;
	    top: -1vh;
	    left: -1vh;
	}

	.scene .game-champion {

		bottom: 32vh;
	}

	.scene .game-enemies {

		bottom: 32vh;
	}

	.fight-end-turn {
	    bottom: 1vw;
	    right: 1vw;
	}

	.locations-info {
	    position: absolute;
	    bottom: calc(5vw + 5vh);
	    right: calc(1.75vw + 0.75vh);
	    height: calc(1rem + 1.5vw + 1vh);
	    cursor: pointer;
	}

	.mana-count-wrapper {
	    position: absolute;
	    height: calc(4.5vh + 4.5vw);
	    width: calc(4.5vh + 4.5vw);
	    bottom: calc(21.5vh - 2.5vw);
	    left: 18.75vw;
	}

	.mana-count {

		font-size: calc(5vh + 5vw);
	}

	.champion-stat {
	    height: 2rem;
	    width: 6.5rem;
	    font-size: 4vh;
	    position: relative;
	    z-index: 21;
	}

	.champion-stat img {
	    height: 2rem;
	    width: 2rem;
	    position: absolute;
	    top: 0;
	    left: 0;
	    filter: drop-shadow(2px 2px);
	}

	.champion-info {
	    position: absolute;
	    top: 1rem;
	    left: 6.5rem;
	    height: 2rem;
	    width: 20vw;
	    justify-content: center;
	}

	.champion-stat#champion-level {
	    width: 3.5rem;
	}

	.champion-stat .stat-count {
	    height: 2rem;
	    width: 3rem;
	    margin-left: 0.25rem;
	    position: absolute;
	    top: 0;
	    left: 2rem;
	    align-items: center;
	    white-space: nowrap;
	    font-size: 1.3rem;
	    font-weight: bold;
	    color: white;
	    text-shadow: 0.15rem 0.15rem 0.1rem #00000080;
	}

	.champion-info + .champion-info {
	    left: unset;
	    right: 5rem;
	    width: 25vw;
	}

	.champion-difficulty {
	    width: 2rem;
	    height: 2rem;
	    position: absolute;
	    top: 1rem;
	    left: calc(50% - 28vw + 9.5rem);
	    filter: drop-shadow(2px 2px);
	    z-index: 21;
	}

	.exp-bar-wrapper {
	    height: 0.5rem;
	    width: calc(33vw - 8.9rem);
	    position: absolute;
	    top: 1.75rem;
	    left: calc(50% - 27vw + 11.5rem);
	    z-index: 21;
	}

	.exp-bar {
	    height: 0.5rem;
	    width: calc(33vw - 8.9rem);
	    background: #3e3a0e82;
	    border-radius: 0.375vh;
	    overflow: hidden;
	    box-shadow: 0.3vh 0.3vh 0.2vh 0px #00000080;
	}

	.exp-bar-text {
	    position: absolute;
	    width: calc(30vw - 7.4rem);
	    justify-content: center;
	    color: white;
	    font-size: 1.05rem;
	    top: -1.75vh;
	    text-shadow: 0.3vh 0.3vh 0.2vh #00000080;
	}

	.scene-quest {

	    top: 15vh;
	    z-index: 18;
	    right: unset;
	    left: calc(7vh + 48vh);
	    width: unset;
	    max-width: calc(91.25vw - 48vh);
	    padding: 0.5vh 1.5vh;
	    font-size: 3vh;
	    flex-direction: row;
	    justify-content: center;
	    font-size: 4vh;
	    overflow: hidden;
	    flex-wrap: nowrap;
	}

	.scene-quest h2 {

		font-size: 4vh;
	}

	.scene-quest h2::after {

		content: ":";
		margin-right: 0.25em;
	}

	.scene-quest .quest-text {
	    line-height: unset;
	    text-align: center;
	    justify-content: center;
	    white-space: nowrap;
	}
}