.game-enemies {

  justify-content: center;
  pointer-events: none;
}

.game-enemies .enemy-wrapper {

  margin: 0 1vw;
  transition: opacity 0.5s;
}

.game-enemies .enemy-wrapper.dead {

  opacity: 0;
  pointer-events: none;
}

.game-enemies .enemy-wrapper.dead .game-entity .game-entity-intent {

  pointer-events: none;
}