.game-unit {

	width: 8.3em;
	height: 6.3em;
	position: relative;
	filter: grayscale(0) brightness(1);
	transition: filter 0.2s;
	cursor: pointer;
}

.game-unit .card-image-wrapper {

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	justify-content: center;
	overflow: hidden;
	border-radius: 3.5vw;
}

.game-digitanim {

  display: none;
  position: absolute;
  top: -50%;
  left: -50%;
  font-size: 6em;
  z-index: 1;
  text-shadow: 0px 0px 4px rgb(0, 0, 0);
  color: #ffffff;
  font-weight: bold;
  width: 200%;
  height: 200%;
  line-height: 2em;
  align-items: center;
  transform: translateY(1px);
}

.game-animmask {

  display: none;
  position: absolute;
  border-radius: 3.5vw;
  top: 0em;
  left: 0em;
  width: 100%;
  height: 100%;
  transform: translateY(1px);
}

.game-hero .game-animmask {

  border-radius: 50%;
}

.game-card-targetable .game-unit .card-image-wrapper {

	animation: card-target-shadow 1s alternate infinite;
	filter: contrast(1.2);
}

@keyframes card-target-shadow {
	from { box-shadow: 0 0 0.6em 0.2rem #aa0000; }
	to { box-shadow: 0 0 0.6em 0.3rem #aa0000; }
}

.game-card-untargetable .game-unit {

	filter: grayscale(0.7) sepia(0.3);
}

.game-unit .card-image-wrapper img {

	object-fit: cover;
}

.game-unit .card-stat {

	position: absolute;
	bottom: -0.6em;
	right: -0.6em;
	width: 3.5em;
	height: 3.5em;
	object-fit: contain;
  pointer-events: none;
}

.game-unit .card-stat-icon {

	position: absolute;
	top: 0;
	left: 0;
	width: 3.5em;
	height: 3.5em;
	object-fit: contain;
}

.game-unit .card-atk {

	left: -0.6em;
	right: unset;
}

.game-unit .card-mark {

  top: -0.6em;
  bottom: unset;
}

.game-unit .card-stat-value {

	position: absolute;
	top: 0;
	left: 0;
	color: white;
	font-size: 2.683em;
	width: 1.3em;
	justify-content: center;
	text-align: center;
	-webkit-text-fill-color: white;
    -webkit-text-stroke-width: 0.08rem;
    -webkit-text-stroke-color: #1c2530;
    font-family: Impact;
}

.game-unit .card-stat-value.card-stat-value-buff, .game-hero .card-stat-value.card-stat-value-buff {

	-webkit-text-fill-color: #00e51c;
    -webkit-text-stroke-color: #1b351b;
}

.game-unit .card-stat-value.card-stat-value-debuff, .game-hero .card-stat-value.card-stat-value-debuff {

	-webkit-text-fill-color: #ff0000;
    -webkit-text-stroke-color: #e9b3b3;
}

.game-unit.state-freeze .card-atk .card-stat-value {

	-webkit-text-fill-color: #4ee4ff;
    -webkit-text-stroke-color: #228c97;
}

.game-unit.game-unit-sickness {

	filter: grayscale(0.5) brightness(0.6);
}





.game-unit .game-exalted {

  position: absolute;
  top: 0em;
  left: 0em;
  font-size: 1em;
  width: 8.3em;
  height: 6.3em;
    border-radius: 3.5vw;
  animation: game-exalted 7s infinite linear;
}

@keyframes game-exalted {
  0% { background: rgba(255, 255, 255, 0); box-shadow: 0px 0px 1em 0.4em rgba(225,225,225,0); }
  20% { background: rgba(255, 255, 255, 0.4); box-shadow: 0px 0px 1em 0.4em rgba(225,225,225,0.8); }
  50% { background: rgba(255, 255, 255, 0); box-shadow: 0px 0px 1em 0.4em rgba(225,225,225,0); }
  80% { background: rgba(205, 255, 255, 0.4); box-shadow: 0px 0px 1em 0.4em rgba(185,225,225,0.8); }
  100% { background: rgba(255, 255, 255, 0); box-shadow: 0px 0px 1em 0.4em rgba(225,225,225,0); }
}





.game-unit.game-unit-sickness.state-agility {

  filter: none;
}

.game-unit.game-unit-actioned.state-agility {

  filter: grayscale(0.5) brightness(0.6);
}

.game-unit.game-unit-sickness .game-agility {

  position: absolute;
  top: 0em;
  left: 0em;
  font-size: 1em;
  width: 8.4em;
  height: 6.4em;
    border-radius: 3.5vw;
  animation: game-agility 7s infinite linear;
  transition: opacity 0.3s;
}

.game-unit.game-unit-actioned .game-agility {

  opacity: 0;
}

@keyframes game-agility {
  0% { background: rgba(150, 210, 150, 0.1); box-shadow: 0px 0px 1em 0.4em rgba(175,255,175,0.4); }
  20% { background: rgba(150, 210, 150, 0.3); box-shadow: 0px 0px 1em 0.4em rgba(175,255,175,0.8); }
  50% { background: rgba(150, 210, 150, 0.1); box-shadow: 0px 0px 1em 0.4em rgba(175,255,175,0.4); }
  80% { background: rgba(150, 210, 150, 0.3); box-shadow: 0px 0px 1em 0.4em rgba(175,255,175,0.8); }
  100% { background: rgba(150, 210, 150, 0.1); box-shadow: 0px 0px 1em 0.4em rgba(175,255,175,0.4); }
}





.game-unit .game-shield {

	width: 10.3em;
    height: 8.3em;
    overflow: hidden;
    position: absolute;
    top: -1em;
    left: -1em;
    border-radius: 3.5vw;
    z-index: -1;
    background: rgba(255,255,255,0.34);
    box-shadow: inset 0px 0px 5em 0px rgba(224, 228, 11, 0.5), 0px 0px 2em 0px rgba(255,235,180,0.8);
	animation: shieldcolor 3s infinite alternate, shieldsize 2s infinite alternate;
}

@keyframes shieldcolor {
  from {background: rgba(255,255,255,0.34); box-shadow: inset 0px 0px 10em 0px rgba(244, 208, 7, 0.5), 0px 0px 2em 0px rgba(255,235,180,0.8); }
  to {background: rgba(240, 240, 200, 0.34); box-shadow: inset 0px 0px 10em 0px rgba(254, 188, 2, 0.5), 0px 0px 2em 0px rgba(255,235,180,0.8); }
}

@keyframes shieldsize {
  from {top: -0.6em; left: -0.6em; width: 9.3em; height: 7.3em; }
  to {top: -0.9em; left: -0.9em; width: 9.9em; height: 7.9em; }
}





.game-unit .game-undying {

	width: 10.3em;
  height: 8.3em;
  overflow: hidden;
  position: absolute;
  top: -1em;
  left: -1em;
  border-radius: 3.5vw;
  z-index: -1;
  background: rgba(255,255,255,0.34);
  box-shadow: inset 0px 0px 5em 0px rgba(224, 228, 11, 0.5), 0px 0px 2em 0px rgba(255,235,180,0.8);
	animation: undyingcolor 3s infinite alternate, undyingsize 2s infinite alternate;
}

.game-unit .game-shield + .game-undying {

	width: 9.3em;
  height: 7.3em;
  top: -0.5em;
  left: -0.5em;
	animation: undyingcolor 3s infinite alternate, smallundyingsize 2s infinite alternate;
}

@keyframes undyingcolor {
  from {background: rgba(255,255,255,0.34); box-shadow: inset 0px 0px 10em 0px rgba(7, 212, 244, 0.5), 0px 0px 2em 0px rgba(13, 118, 217,0.8); }
  to {background: rgba(200, 220, 240, 0.34); box-shadow: inset 0px 0px 10em 0px rgba(46, 167, 232, 0.5), 0px 0px 2em 0px rgba(13, 118, 217,0.8); }
}

@keyframes undyingsize {
  from {top: -0.6em; left: -0.6em; width: 9.3em; height: 7.3em; }
  to {top: -0.9em; left: -0.9em; width: 9.9em; height: 7.9em; }
}

@keyframes smallundyingsize {
  from {top: -0.2em; left: -0.2em; width: 8.7em; height: 6.7em; }
  to {top: -0.3em; left: -0.3em; width: 8.9em; height: 6.9em; }
}





.game-unit .game-freeze {

	width: 8.4em;
  height: 6.4em;
  box-sizing: border-box;
  overflow: hidden;
  margin: 0;
  position: absolute;
  top: -0.05em;
  left: -0.05em;
  border-radius: 3.5vw;
  background: rgba(255,255,255,0.34);
  box-shadow: inset 0px 0px 5em 0px rgba(224, 228, 11, 0.5), 0px 0px 2em 0px rgba(255,235,180,0.8);
  animation: freezecolor 6s infinite alternate;
}

@keyframes freezecolor {
  from {background: rgba(255,255,255,0.3); box-shadow: inset 0px 0px 1em 1em rgba(220, 248, 255, 0.95), 0px 0px 0.3em 0px rgba(220,248,255,0.95); }
  to {background: rgba(180, 215, 240, 0.3); box-shadow: inset 0px 0px 1em 1em rgba(170, 240, 255, 0.95), 0px 0px 0.3em 0px rgba(170,240,255,0.95); }
}

.game-unit .game-freeze::before { animation: freeze1 3.5s infinite linear }

.game-unit .game-freeze::after { background: rgba(97, 220, 245,0.34); animation: freeze2 3.5s infinite linear; animation-delay: 1s; }

@keyframes freeze1 {
  0% { transform: rotateZ(0deg); width: 0; height: 0; top: -1em; left: 0; }
  20% { transform: rotateZ(35deg); width: 10em; height: 10em; top: 1em; left: 1em; }
  50% { transform: rotateZ(85deg); width: 14em; height: 14em; top: 4em; left: 2em; }
  80% { transform: rotateZ(135deg); width: 10em; height: 10em; top: 9em; left: 8em; }
  100% { transform: rotateZ(170deg); width: 0; height: 0; top: 16em; left: 12em; }
}

@keyframes freeze2 {
  0% { transform: rotateZ(80deg); width: 0; height: 0; top: 18em; left: 0; }
  20% { transform: rotateZ(135deg); width: 10em; height: 10em; top: 15em; left: 1em; }
  50% { transform: rotateZ(200deg); width: 14em; height: 14em; top: 8em; left: 1em; }
  80% { transform: rotateZ(265deg); width: 10em; height: 10em; top: 6em; left: 6em; }
  100% { transform: rotateZ(320deg); width: 0; height: 0; top: 3em; left: 12em; }
}






.game-unit .game-silence {

	animation: silencetranslate 3s infinite alternate;
    position: absolute;
    top: 0em;
    left: 0em;
}

.game-unit .game-silence::before {

	content: "";
	width: 10em;
  height: 10em;
  overflow: hidden;
  margin: 0;
  position: absolute;
  top: -2em;
  left: -1em;
  background: url('../images/silence.png');
  background-size: 100%;
  animation: silencerotate 10s infinite linear;
}

.game-unit .game-silence::after {

	content: "";
	width: 5.5em;
  height: 5.5em;
  overflow: hidden;
  margin: 0;
  position: absolute;
  top: 0.25em;
  left: 1.25em;
  transform: translateY(-0.5em);
  background: url('../images/silence.png');
  background-size: 100%;
  animation: silencerotate 10s infinite linear reverse;
}

@keyframes silencerotate {
  from {transform: rotateZ(0deg);}
  to {transform: rotateZ(360deg);}
}

@keyframes silencetranslate {
  from {transform: translateY(0);}
  to {transform: translateY(0);}
}







.game-unit .game-lastwill {

  position: absolute;
  bottom: -1em;
  left: 2.9em;
  width: 2.5em;
  height: 2.5em;
  object-fit: contain;
  filter: drop-shadow(0.1rem 0.1rem #00000060);
}

.game-unit .card-lastwill-icon {

  position: absolute;
  top: 0;
  left: 0;
  width: 2.5em;
  height: 2.5em;
  object-fit: contain;
}






.game-unit.state-hidden {

  filter: grayscale(1) brightness(0.5);
}





@media (min-width: 601px) {

  .game-unit {

   font-size: 0.95em; 
  }

	.game-unit .card-image-wrapper {

		border-radius: 3.5vh;
	}
	
	.game-unit .game-shield {

		border-radius: 3.5vh;
  }
	
	.game-unit .game-undying {

		border-radius: 3.5vh;
  }
  
  .game-unit .game-freeze {

    border-radius: 3.5vh;
  }
  
  .game-unit.game-unit-sickness .game-agility {

    border-radius: 3.5vh;
  }

  @keyframes card-target-shadow {
    from { box-shadow: 0 0 0.9em 0.4rem #aa0000; }
    to { box-shadow: 0 0 0.9em 0.55rem #aa0000; }
  }

  .game-animmask {

    border-radius: 3.5vh;
  }
}