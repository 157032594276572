.cards-page .main {

	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.cards-page-nav {

	height: 4rem;
	width: 100%;
	border-bottom: 0.2rem lightgray solid;
	background: #0b0e1c;
}

.cards-page-search {
	
	width: 100%;
	box-shadow: 0px 0.2rem 0.2rem 0px #000000A0;
	z-index: 99;
}

.cards-page-search-tab {
	
	height: 2.5rem;
	width: 100%;
	border-left: solid 1px #ffffff1c;
	border-bottom: solid 1px #ffffff1c;
	background: linear-gradient(60deg, #000000A0, #00000050);
	flex-direction: column;
	padding: 0.2rem 0.3rem;
	font-size: 1rem;
	overflow: hidden;
}

.cards-page-search-tab:active {

	background: linear-gradient(60deg, #00000050, #00000020)
}

.cards-page-search-tab:nth-child(1) {
	
	border-left: none;
}

.cards-page-search-tab .cards-page-search-label {

	color: #7f7a6a;
	white-space: nowrap;
}

.cards-page-search-tab .cards-page-search-value {

	color: #edeae4;
	white-space: nowrap;
	max-width: 100%;
}

.cards-page-search-main {

	width: 100%;
	height: 100%;
}

.cards-page-search-open-deckbuilder {

	display: none;
	cursor: pointer;
	background-color: transparent;
	transition: background-color 0.2s;
}

.cards-page-search-open-deckbuilder:hover {

	background-color: #ffffff10;
}

.cards-page-search-open-deckbuilder:hover .cards-page-search-main-label {

	color: #96928B;
}

.cards-page-search-open-deckbuilder:active {

	background-color: #ffffff30;
}

.cards-page-search-open-deckbuilder:active .cards-page-search-main-label {

	color: #C3C0B0;
}

.cards-page-search .cards-page-search-main-label {

	color: #86837E;
	white-space: nowrap;
	font-size: 1.6rem;
	line-height: 2.5rem;
	justify-content: center;
	height: 100%;
	transition: color 0.2s;
	width: 100%;
}

.selectbox {

  flex-direction: column;
  color: #edeae4;
  width: 100%;
}

.selectbox button {

  padding-top: 0.5rem;
  font-size: 1.5rem;
  min-width: 12rem;
  justify-content: center;
  align-items: center;
  background: none;
  position: relative;
  transition: all 0.2s linear;
  cursor: pointer;
  flex-direction: column;
  color: #edeae4;
}

.selectbox button::after {

	content: "";
	margin-top: 0.5rem;
	width: 100%;
	height: 0.1rem;
	background: linear-gradient(to right, transparent, #7f7a6a, transparent);
}

.selectbox button:last-child::after {

	content: none;
}

.cards-page-decks {

	width: 100%;
	border-bottom: 0.1rem lightgray solid;
	flex-direction: column;
}

.cards-page-list {

	height: calc(100% - 9rem);
	width: 100%;
	position: relative;
	overflow: auto;
	flex-direction: column;
	padding-bottom: 5rem;
}

.cards-border {

	height: 1.4rem;
	width: 100%;
	margin-top: 0.4rem;
	background: linear-gradient(to right, #000000A0, #00000050, #000000A0);
	line-height: 1.4rem;
	justify-content: space-between;
	padding: 0 0.2rem;
	padding-right: 0.5rem;
	color: #edeae4;
	box-sizing: border-box;
}

.cards-border-cardcount {

	margin-left: 0.5em;
	opacity: 0.3;
	font-size: 0.8em;
	margin-top: 0.1em;
}

.color-list {
	
	width: 100%;
	flex-direction: column;
}

.color-border {
	
	height: 1.4rem;
	width: 100%;
	margin-top: 0.4rem;
	background: linear-gradient(to right, #00000050, #00000028);
	line-height: 1.4rem;
	justify-content: space-between;
	padding: 0 0.2rem;
	padding-right: 0.5rem;
	color: #edeae4;
	box-sizing: border-box;
}

.card-list {

	width: 100%;
	flex-wrap: wrap;
	margin-top: 0.35rem;
	box-sizing: border-box;
	flex-direction: column;
	align-items: center;
}

.card-list.champions {

	justify-content: center;
	flex-direction: row;
}

.card-list .champion-selector {

	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.card-list .champion-selector .main-button {

	font-size: 1.2rem;
	width: 6rem;
	min-width: unset;
}

.card-list .champion-selector.champion-selected .main-button {

	cursor: default;
	background: linear-gradient(to bottom, #5a5a5a, #999999);
	color: #505050;
}

.card-list .champion-selector.champion-selected .main-button:hover {

}

.card-list .champion-selector.champion-selected .main-button::after {

	content: none;
}

.level-list {

	margin-bottom: 1rem;
	padding-top: 0.5rem;
	width: 100%;
	justify-content: center;
	position: relative;
	flex-flow: wrap;
}

.level-list::before {

	content: "";
	height: 1px;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: linear-gradient(to right, transparent, #edeae460, transparent);
}

.level-list:nth-child(1) {

	padding-top: 0;
}

.level-list:nth-child(1)::before {

	content: none;
}

.card-list .listed-card {

	padding: 0.1rem;
	font-size: 0.6rem;
	height: calc(9.5em + 0.6rem);
	width: calc(6.5em + 0.6rem);
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.deckbuilder .card-list {

	padding-right: 1.2rem;
}

.card-list .listed-cards {

	padding: 0.1rem;
	font-size: 0.6rem;
	height: calc(6em + 0.6rem);
	width: calc(8em + 0.6rem);
	justify-content: center;
	align-items: center;
	cursor: pointer;
	display: block;
	white-space: nowrap;
}

#cards-page-category-label {

	display: none;
}

.card-list .listed-cards .sensuba-card {

	display: inline-block;
}

.card-list .listed-cards .sensuba-card + .sensuba-card {

	margin-left: calc(0px - 5em - 0.6rem);
	transform: translateY(0.2em);
	z-index: 1;
}

.card-list .listed-deck {

	padding: 0.1rem;
	font-size: 0.6rem;
	height: calc(6em + 0.6rem);
	width: calc(8em + 0.6rem);
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-bottom: 0.6rem;
	margin-left: 0.3rem;
	margin-right: 0.3rem;
}

.card-list .listed-deck.selected-deck .sensuba-deck .card-image-wrapper {

	box-shadow: 0 0 0.2rem 0.15rem #e6dcc0;
}

.card-list .listed-card .wanderaft-card {

	transition: font-size 0.2s;
}

.card-list .listed-card:hover .wanderaft-card {

	font-size: 1.06em;
}

.card-list .wanderaft-card {

	filter: drop-shadow(0.3rem 0.3rem);
}

.card-list-info {

	height: calc(100% - 16vh);
	margin-top: 8vh;
	justify-content: flex-start;
	width: 29rem;
	padding: 0.5rem;
	flex-direction: column;
}

.card-list-filter {

	width: 100%;
	flex-direction: column;
}

.card-list-filter-el {

	width: 100%;
	margin: 0.5rem;
}

.card-filter-label {

	width: 6rem;
}

.card-list-info .form-select {

	padding: 0.25rem;
	border: none;
	background: #d1caae;
}

.cards-page .card-box {

	font-size: 1rem;
}

.cards-page .deckbuilder {

	margin-top: 1rem;
	flex-direction: column;
}

.cards-page .deckbuilder .deckbuilder-nav {

	flex-direction: column;
	margin-bottom: 0.3rem;
}

.cards-page .deckbuilder .deckbuilder-nav-row {

	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 0.3rem;
}

.cards-page .deckbuilder .deckbuilder-nav-row + .deckbuilder-nav-row {

	justify-content: space-around;
}

.cards-page .deckbuilder .deckbuilder-name {

	height: 3rem;
}

.cards-page .deckbuilder .deckbuilder-name input {

	background: none;
	border: none;
	font-size: 1.2rem;
	color: #DEC6A2;
	width: 100%;
	border-bottom: 1px solid #DEC6A2;
	border-radius: 0.3rem;
	padding: 0 0.6rem;
	box-sizing: border-box;
}

.cards-page .deckbuilder .deckbuilder-status {

	color: #ffcd00;
	width: 2.5rem;
}

.cards-page .deckbuilder .deckbuilder-valid-count .deckbuilder-status {

	color: #DEC6A2;
}

.cards-page .deckbuilder .deckbuilder-count {

	color: #ffcd00;
	font-weight: bold;
	line-height: 2.5rem;
	margin-left: 1em;
}

.cards-page .deckbuilder .deckbuilder-count.deckbuilder-valid-count {

	color: #DEC6A2;
}

.cards-page .deckbuilder .deckbuilder-h {

	color: #edeae4;
	line-height: 2.5rem;
	margin: 0.5em 1em;
}

.cards-page .deckbuilder .deckbuilder-delete {

}

.deckbuilder-delete .main-button-container {

}

.deckbuilder-buttons .main-button-container {

	margin: 0.6rem;
}

.deckbuilder-delete .main-button, .deckbuilder-back .main-button {

	font-size: 1.2rem;
  padding: 0.333rem;
  min-width: 5.33rem;
}

.delete-box h1 {

	text-align: center;
  align-self: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  line-height: 2rem;
  justify-content: center;
  white-space: nowrap;
  color: #edeae4;
}

.cards-page .deckbuilder .deckbuilder-champion {

	font-size: 0.6rem;
	cursor: pointer;
}

.cards-page .cards-page-cardcountbuilder {

	position: absolute;
	top: calc(50% + 10rem);
	left: calc(50% - 6.5rem - 2px);
	width: 13rem;
	z-index: 1001;
	justify-content: center;
	border-radius: 1rem;
	overflow: hidden;
	border: 2px solid white;
	font-size: 1.4rem;
}

.cards-page .cards-page-cardcountbuilder .cards-page-cardcountbutton {

	height: 3rem;
	width: 4rem;
	background: #DEC6A2;
	justify-content: center;
	align-items: center;
	transition: all 0.2s;
	cursor: pointer;
}

.cards-page .cards-page-cardcountbuilder .cards-page-cardcountbutton:active {

	background: #a18c6d;
}

.cards-page .cards-page-cardcountbuilder .cards-page-cardcountbutton.cards-page-cardcountbuttonlock {

	background: #c6bbab;
	color: #8f8f8f;
	cursor: default;
}

.cards-page .cards-page-cardcountbuilder .cards-page-cardcount + .cards-page-cardcountbutton {

}

.cards-page .cards-page-cardcountbuilder .cards-page-cardcount {

	height: 3rem;
	width: 5rem;
	background: #DEC6A2;
	justify-content: center;
	align-items: center;
	border-left: 1px solid #ccb591;
	border-right: 1px solid #ccb591;
}

.cards-page .champions-section {

	flex-direction: column;
}

.cards-page .deck-list {

	width: 100vw;
	overflow: auto;
	flex-wrap: nowrap;
	position: relative;
	justify-content: left;
	padding: 0 0.5rem;
}

.cards-page-deck-section {
	
		display: none;
}

.cards-page-deck-section.hidden-decks .deck-list-carousel, 	.cards-page-deck-section.hidden-decks .deckbuilder {

	display: none;
}

.cards-page .new-deck {

	width: 8em;
	height: 6em;
	position: relative;
  	filter: drop-shadow(#00000060 0.15rem 0.15rem);
}

.cards-page .new-deck::before {

	content: "";
	position: absolute;
	top: 0;
	left: 0;
	justify-content: center;
	overflow: hidden;
	width: 8em;
	height: 6em;
	border-radius: 50%;
	opacity: 1;
	transition: opacity 0.5s;
	background: #f1efeb80;
}

/*.cards-page .new-deck::after {

	content: "";
	position: absolute;
	top: 0.5em;
	left: 0.5em;
	width: 7em;
	height: 5em;
	background: #FFFFFF00;
}*/

.cards-page .new-deck::after {

	content: "+";
	display: flex;
	position: absolute;
	font-weight: bold;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	color: #2d2921;
}

.cards-page .new-deck-chose-champion-box h2 {

	font-size: 2rem;
	color: #edeae4;
	justify-content: center;
	white-space: nowrap;
}

.cards-page .new-deck-chose-champion-box .card-list {

	height: calc(13rem - 1em);
	overflow: scroll;
}

.cards-page-deck-section {

		background: linear-gradient(60deg, #00000080, #00000030);
		padding-bottom: 0.4rem;
}

.cards-page-deck-section, .cards-page-championsandcards-section {

	flex-direction: column;
}

#champions-border {

	margin-top: 0;
}

.cards-page .sensuba-card, .cards-page .sensuba-deck, .cards-page .sensuba-champion {

	justify-content: center;
   -webkit-transform: translateZ(0);
   -moz-transform: translateZ(0);
   -ms-transform: translateZ(0);
   -o-transform: translateZ(0);
   transform: translateZ(0);
}

.champion-selector .sensuba-champion img {

	filter: brightness(0.5);
}

.champion-selector.champion-selected .sensuba-champion img {

	filter: brightness(1);
}

.cards-border-change-page {

	padding: 0 0.5em;
	cursor: pointer;
}

.cards-border-change-page.locked {

	opacity: 0.3;
	cursor: default;
}

@media (min-width: 601px) {

	.cards-page .card-list {

		max-width: 80vw;
		margin: auto;
	}
  
	.card-list .listed-card {

		font-size: 1rem;
		height: calc(9.5em + 1rem);
		width: calc(6.5em + 1rem);
	}
  
	.card-list .listed-cards {

		font-size: 1rem;
		height: calc(9.5em + 1rem);
		width: calc(6.5em + 1rem);
	}

	.card-list .listed-deck {

		font-size: 1rem;
		height: calc(6em + 1rem);
		width: calc(8em + 1rem);
		margin-bottom: 1rem;
		margin-left: 0.5rem;
		margin-right: 0.5rem;
	}

	.card-list .listed-deck.selected-deck .sensuba-deck .card-image-wrapper {

		box-shadow: 0 0 0.6rem 0.5rem #e6dcc0;
	}

	.cards-page-deck-section.hidden-decks .deck-list-carousel, 	.cards-page-deck-section.hidden-decks .deckbuilder {

		display: inherit;
	}

	.cards-page .hideable-border {
		
		cursor: pointer;
	}

	.cards-page-deck-section .cards-border {
		
		display: none;
	}

	.cards-page .deckbuilder .deckbuilder-champion {

		font-size: 1rem;
	}

	.cards-border {

		height: 2.2rem;
		width: 100%;
		margin-top: 0.8rem;
		margin-bottom: 0.4rem;
		background: linear-gradient(to right, #000000A0, #00000050, #000000A0);
		line-height: 2.2rem;
		justify-content: center;
		color: #edeae4;
		box-sizing: border-box;
	}

	.color-border {
		
		height: 2.2rem;
		width: 100%;
		margin-top: 0.8rem;
		margin-bottom: 0.4rem;
		background: linear-gradient(to right, #00000050, #00000028);
		line-height: 2.2rem;
		justify-content: center;
		color: #edeae4;
		box-sizing: border-box;
	}

	.cards-page-list .cards-border .cards-border-right, .cards-page-list .color-border .cards-border-right {

		display: none;
	}

	.cards-page-list .cards-border .cards-border-left, .cards-page-list .color-border .cards-border-left {

		justify-content: center;
	}

	.cards-page .cards-page-cardcountbuilder {

    top: calc(50% + 14rem);
    left: calc(50% - 7.5rem - 2px);
    width: 15rem;
    font-size: 1.5rem;
	}

	.cards-page .cards-page-cardcountbuilder .cards-page-cardcountbutton {

		height: 3.4rem;
		width: 4.6rem;
	}

	.cards-page .cards-page-cardcountbuilder .cards-page-cardcount {

		height: 3.4rem;
		width: 5.8rem;
	}

	.cards-page-search {

	    box-shadow: 0px 0.2rem 0.2rem 0px #000000a0;
	    z-index: 99;
	}

	.cards-page-list {

		transition: width 0.35s, margin-left 0.35s;
	}

	.cards-page-list.restrained-list {

		width: calc(80% - 15rem);
		margin-left: calc(0px - 20% - 15rem);
	}

	.cards-page-deck-section.hidden-decks {

		width: 0;
	}

	.cards-page-deck-section {

		position: fixed;
		transition: width 0.35s;
		bottom: 0;
		right: 0;
		width: calc(20% + 15rem);
		height: calc(100% - 7.9rem);
		padding-top: 0rem;
		overflow: auto;
		padding-bottom: 1rem;
	}

	.cards-page-championsandcards-section {

	}

	.cards-page-search-main {

		width: calc(100% - 20vw - 15rem);
	}

	.cards-page-search-open-deckbuilder {

		display: inherit;
		height: 2.5rem;
    width: calc(20vw + 15rem);
    border-left: solid 1px #ffffff1c;
    border-bottom: solid 1px #ffffff1c;
    background: linear-gradient(60deg, #000000A0, #00000050);
    flex-direction: column;
    padding: 0.2rem 0.3rem;
    font-size: 1rem;
    overflow: hidden;
	}

	.cards-page-search-tab {
		
		cursor: pointer;
	}

	.cards-page-search-tab:hover {

		background: linear-gradient(60deg, #00000050, #00000020)
	}

	.cards-page-search-tab:active {

		background: linear-gradient(60deg, #FFFFFF16, #FFFFFF06)
	}

	.cards-page .deckbuilder .deckbuilder-name {

		height: 4rem;
	}

	#champions-border {

		margin-top: 0.8rem;
	}

	.cards-page .deckbuilder .deckbuilder-nav {

		margin-bottom: 0.7rem;
	}

	.deckbuilder-delete .main-button, .deckbuilder-back .main-button {

		font-size: 1.35rem;
	  padding: 0.375rem;
	  min-width: 6rem;
	}

	.delete-box h1 {

		font-size: 2.5rem;
		line-height: 2.5rem;
		margin-left: 1rem;
		margin-right: 1rem;
	}

	#cards-page-category-label {

		display: inherit;
	}

	.cards-border-page {
		margin-left: 0.5em;
	}
}