.cutscene .lightbox-container {

  background: #000000D0;
  z-index: 20;
  animation: cutscene-appear 0.6s ease-in-out;
}

@keyframes cutscene-appear {
  from { opacity: 0 }
  to { opacity: 1 }
}

.cutscene-inner {

  height: 90vh;
  width: 90vw;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cutscene-character {

  position: absolute;
  bottom: 0;
  height: 90vh;
  width: 50vh;
  transition: filter 0.2s ease-in-out, opacity 0.2s ease-in-out;
  animation: cutscene-character-appear 0.4s ease-out;
  z-index: 1;
}

.cutscene-character.unfocus {

  filter: brightness(0.65);
  z-index: 0;
}

.cutscene-character.hide  {

  opacity: 0 !important;
}

@keyframes cutscene-character-appear {

  from { opacity: 0; transform: translateX(2rem); }
  to { opacity: 1; transform: translateX(0rem); }
}

.cutscene-character.cutscene-left, .cutscene-character.cutscene-left2 {

  left: calc(20vw - 10vh);
  transform: scaleX(-1);
  animation: cutscene-character-appear-left 0.4s ease-out;
}

.cutscene-character.cutscene-left2 {

  left: calc(32vw - 8vh);
}

@keyframes cutscene-character-appear-left {

  from { opacity: 0; transform: scaleX(-1) translateX(2rem); }
  to { opacity: 1; transform: scaleX(-1) translateX(0rem); }
}

.cutscene-character.cutscene-right {

  right: calc(20vw - 10vh);
}

.cutscene-character.cutscene-right2 {

  right: calc(32vw - 8vh);
}

.cutscene-character img {

  height: 100%;
  object-fit: cover;
}

.cutscene-background {

  position: absolute;
  height: 100vh;
  width: 100vw;
}

.cutscene-background img {

  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cutscene-img {

  position: relative;
  margin-top: 4vh;
  height: 66vh;
  width: 96.46vh;
}

.cutscene-img.none {

  opacity: 0;
  pointer-events: none;
}

.cutscene-img img {

  height: 100%;
  object-fit: cover;
}

.cutscene-img:after {

  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: 0 0 1vh 0.5vh white, inset 0 0 1vh 0.5vh white;
}

.cutscene-textbox {

  height: 16vh;
  width: 90vh;
  margin-top: 4vh;
  background: #FFFFFFC0;
  box-shadow: 0 0 1vh 2vh #FFFFFFC0;
  flex-direction: column;
  position: relative;
  z-index: 2;
}

.cutscene-textbox .cutscene-speaker {

  font-size: calc(3vh);
  margin-bottom: 0.6vh;
  font-weight: bold;
  width: 100%;
  text-align: left;
}

.cutscene-textbox .cutscene-text {

  font-size: calc(2.9vh);
  line-height: 1em;
  width: 100%;
  text-align: justify;
}

.cutscene-textbox .cutscene-back, .cutscene-textbox .cutscene-skip {

  position: absolute;
  color: #2a313b;
  bottom: -0.5em;
  left: calc(50% - 5em);
  font-size: 0.7em;
  width: 5em;
  font-weight: bold;
  justify-content: center;
  cursor: pointer;
  transition: color 0.2s, opacity 0.2s;
}

.cutscene-textbox .cutscene-skip {

  left: calc(50% - 0em);
}

.cutscene-textbox .cutscene-back:not(.locked):hover, .cutscene-textbox .cutscene-skip:not(.locked):hover {

  color: #707987;
}

.cutscene-textbox .cutscene-back.locked, .cutscene-textbox .cutscene-skip.locked {

  cursor: default;
  opacity: 0.5;
}

.cutscene-text.italics {

  font-style: italic;
}