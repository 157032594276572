.game-entity .game-entity-damage-anim .game-entity-damage.game-entity-damage-anim {

  animation: game-entity-damage-size 0.55s ease-out 1, game-entity-damage-opacity 0.55s ease-out 1;
}

.game-entity .game-entity-damage-anim .game-entity-img-wrapper img {

  animation: game-entity-damage-color 0.55s ease-out 1, entity-img-size 2s ease-in-out infinite alternate;
}

@keyframes game-entity-damage-color {
  0% { filter: contrast(3) saturate(2) brightness(0.8) }
  100% { filter: none }
}

@keyframes game-entity-damage-opacity {
  0% { opacity: 0 }
  30% { opacity: 1 }
  70% { opacity: 1 }
  100% { opacity: 0 }
}

@keyframes game-entity-damage-size {
  0% { font-size: 3vh }
  100% { font-size: 20vh }
}

.scene .scene-background:after {

  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.scene .scene-background.scene-background-damage:after {

  animation: scene-background-damage-shadow 0.6s ease-out;
}

@keyframes scene-background-damage-shadow {
  0% { box-shadow: inset 0 0 0 0 #FF000000; }
  5% { box-shadow: inset 0 0 5vh 5vh #FF0000A0; }
  100% { box-shadow: inset 0 0 0 0 #FF000000; }
}