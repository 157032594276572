.game-court {

	justify-content: space-around;
	height: 12vw;
    height: 9vw;
	box-sizing: border-box;
	pointer-events: none;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;
	z-index: 19;
}

.game-court-card {

    z-index: 1;
    width: 8vw;
    height: 6vw;
	pointer-events: all;
	transition: 0.4s left, 0.4s transform, 0.2s filter;
	margin: 1vw;
	cursor: pointer;
}

.game-court-card .game-card-wrapper {

	font-size: 3.5vw;
}

@media (min-width: 601px) {

	.scene .game-court {
    
  	width: 40vh;
  	height: 30vh;
  	top: 42vh;
  	left: calc(24vw - 22vh);
  	bottom: unset;
	}

	.scene .opposite-area .game-court {
    
  	top: 12vh;
	}

	.game-court-card .game-card-wrapper {

		font-size: 5vh;
	}
}

@media only screen and (max-width: 600px) {
  
  .game-court-card {

  	width: 50vw;
  	height: 32vw;
  	font-size: 2em;
  	margin: unset;
  	padding: 1%;
  }

  .game-hand {

  	pointer-events: all;
    overflow: auto;
    padding: 1vw;
  }

  .game-hand-card .game-card-wrapper {

  	/*top: 10vw;*/
  	font-size: 2vw;
  }

	.opposite-area .game-hand {

  		transform: none;
  		left: unset;
  		right: 0;
	}

	.opposite-area .game-hand-card {

  		width: 3%;
	}
/*
	.self-area .game-hand-card:hover .game-card-wrapper {

		top: 3vw;
	}

	.opposite-area .game-hand-card .game-card-wrapper {

	    top: 4.5vw;
	}

	.opposite-area .game-hand-card:hover .game-card-wrapper {

		top: 3vw;
	}*/
}


.game-hand-card .game-temporary {

	filter: brightness(0.7) contrast(1.3);
}

.game-hand-card .game-temporary .card-image-wrapper::after {

	content: "";
    position: absolute;
    top: 0em;
    left: 0em;
    width: 8em;
    height: 6em;
    overflow: hidden;
    margin: 0.4em;
    animation: temporary-color 7s infinite linear, temporary-border 4s infinite alternate;
}

@keyframes temporary-color {
  0% { background: rgba(175, 255, 255, 0.25); }
  20% { background: rgba(220, 255, 255, 0.4); }
  50% { background: rgba(175, 255, 255, 0.25); }
  80% { background: rgba(130, 255, 255, 0.4); }
  100% { background: rgba(175, 255, 255, 0.25); }
}

@keyframes temporary-border {
  from { box-shadow: inset 0 0 0.7em 0.35em rgba(64, 0, 128, 0.8), 0 0 0.6em 0.3em rgba(64, 0, 128, 0.8); }
  to { box-shadow: inset 0 0 1em 0.5em rgba(0, 0, 0, 1), 0 0 0.7em 0.35em rgba(0, 0, 0, 1); }
}