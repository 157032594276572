.intent-anim .game-entity-intent {

  opacity: 0;
}

.intent-anim .intent-anim-img {

	animation: intent 0.6s 2 ease-out;
}

.intent-anim .intent-anim-img + .intent-anim-img {

  animation-delay: 0.3s;
}

@keyframes intent {
  from {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
  }
  to {
    height: 300%;
    width: 300%;
    top: -100%;
    left: -100%;
    opacity: 0;
  }
}