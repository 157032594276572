.nav-options {

	width: 2rem;
	height: 2rem;
	cursor: pointer;
	top: 1rem;
	right: 1rem;
	position: absolute;
	font-size: 1.4rem;
}

.nav-options .lightbox-container {

	cursor: default;
}

.options-icon {

	width: 2rem;
	height: 2rem;
	background: url('./options.png');
	background-size: 100%;
	background-repeat: no-repeat;
	filter: brightness(1.0) drop-shadow(0.2rem 0.2rem #000000A0);
	transition: filter 0.2s, transform 0.4s;
	transform: rotateZ(0deg);
}

.game-options .options-icon {

	filter: brightness(1.2) grayscale(1) drop-shadow(0.2rem 0.2rem #000000A0);
}

.main-page.light .options-icon {

	filter: brightness(0.2);
}

.nav-options:hover .options-icon {

	transform: rotateZ(90deg);
}

.options-box {

	flex-direction: column;
}

.options-box h1 {

	text-align: center;
	align-self: center;
	margin-top: 0.5rem;
	margin-bottom: 1.5rem;
	font-size: 2rem;
	line-height: 2rem;
	justify-content: center;
	white-space: nowrap;
	color: #edeae4;
}

.options-row {

	justify-content: space-between;
	margin-top: 0.2em;
	margin-bottom: 0.2em;
}

.options-row .options-param {

	color: /*#7f7a6a*/#a2a9de;
	white-space: nowrap;
}

.options-row .options-value {

	color: #edeae4;
}

.options-row .options-value .options-button {

	color: #3586f1;
	cursor: pointer;
}

.options-row .options-value .options-button:hover {

	color: #3063a6;
}

.options-row .options-radio:not(.options-button) {

	text-decoration: underline;
}

.options-row .options-language {

	color: #3586f1;
	cursor: pointer;
	padding: 0.25rem 0;
	margin: 0.25rem calc(25% - 2rem);
	width: calc(50% + 4rem);
	justify-content: center;
}

.options-row .options-language:hover {

	color: #3063a6;
}

.options-row .options-language.options-language-current {

	cursor: default;
	text-decoration: underline;
}

.options-row .options-language.options-language-current:hover {

	color: #3586f1;
}

.options-row .options-back {

	color: #a2a3de;
	cursor: pointer;
	font-size: 0.8em;
	margin: 0.5rem calc(35% - 2rem);
	margin-top: 0.75rem;
	width: calc(30% + 4rem);
	justify-content: center;
}

.options-row .options-back:hover {

	color: #7976ab;
}

.options-audio-tick {

	height: 100%;
	width: 0.8em;
	cursor: pointer;
	position: relative;
}

.options-audio-tick::after {

	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: calc(100% - 0.2em);
	width: calc(100% - 0.2em);
	margin: 0.1em;
	background: #7f7a6a;
}

.options-audio-tick.audio-tick-active::after {

	background: #3586f1;
}

.options-audio-tick:nth-child(1)::after {

	background: #101010;
}

.options-warning {

	font-size: 1rem;
	justify-content: center;
	text-align: center;
	width: calc(100% - 3rem);
	margin: 0rem 1.5rem;
	margin-top: -1rem;
	margin-bottom: 0.5rem;
	color: #3063a6;
}

.concede-button {

	margin: auto;
	margin-top: 1rem;
}

.game-page .concede-button {

}

@media (max-width: 600px) {

	.concede-button .main-button {

		font-size: 1.2rem;
		padding: 0.333rem;
		min-width: 5.33rem;
	}

}

@media (min-width: 601px) {

	.options-box h1 {

		font-size: 2.5rem;
		line-height: 2.5rem;
		margin-left: 1rem;
		margin-right: 1rem;
	}

	.options-row {

		width: 28rem;
	}

	.options-row .options-language {

		margin: 0.25rem 7.5rem;
		width: 10rem;
	}

	.options-row .options-back {

		margin: 0.5rem 8.5rem;
		margin-top: 0.75rem;
		width: 8rem;
	}

	.options-row .options-param {

		margin-right: 3rem;
	}

	.options-box .options-warning {

		width: 20rem;
		margin: 0rem 2.5rem;
		margin-top: -1rem;
		margin-bottom: 0.5rem;
	}
}