.game-hero {

	position: absolute;
	top: calc(25vh + 80vw - 10vw - 1.5em);
	left: calc(50vw - 4em);
    width: 8em;
    height: 6em;
  	font-size: 2.5vw;
  	z-index: 10;
  	cursor: pointer;
		animation: game-hero-shine alternate infinite 4s;
	}

	@keyframes game-hero-shine {
		from { filter: drop-shadow(0 0 0.4rem #00FFFF40) }
		to { filter: drop-shadow(0 0 0.2rem #00FFFF80) }
	}

.opposite-area .game-hero {

	top: calc(25vh - 10vw - 4.5em);
}

.game-hero.game-card-targetable .card-image-wrapper {
	
	animation: card-target-shadow 1s alternate infinite;
	filter: contrast(1.2);
}

.game-hero .card-stat {

	position: absolute;
	bottom: -0.6em;
	right: 0em;
	width: 3em;
	height: 3em;
	object-fit: contain;
}

.game-hero .card-stat-icon {

	position: absolute;
	top: 0;
	left: 0;
	width: 3em;
	height: 3em;
	object-fit: contain;
}

.game-hero .card-mana {

	left: 0em;
	right: unset;
}

.game-hero .card-stat-value {

	position: absolute;
	top: 0;
	left: 0;
	color: white;
	font-size: 2.3em;
	width: 1.3em;
	justify-content: center;
	text-align: center;
	-webkit-text-fill-color: white;
    -webkit-text-stroke-width: 0.08rem;
    -webkit-text-stroke-color: #1c2530;
    font-family: Impact;
}



.game-hero .game-exalted {

  position: absolute;
  z-index: 1;
  top: 0em;
  left: 0em;
  font-size: 1em;
  width: 8em;
  height: 6em;
    border-radius: 50%;
  animation: game-exalted 7s infinite linear;
}

@media (min-width: 601px) {

	.game-hero {

		position: absolute;
		top: calc(75.5vh - 3em);
		left: calc(50vw - 4em);
	    width: 8em;
	    height: 6em;
	  	font-size: 2.5vh;
		filter: drop-shadow(0.3rem 0.3rem) drop-shadow(0 0 0.3rem #00FFFF80);
		animation: game-hero-shine alternate infinite 4s;
	}

	@keyframes game-hero-shine {
		from { filter: drop-shadow(0.3rem 0.3rem) drop-shadow(0 0 0.6rem #00FFFF40) }
		to { filter: drop-shadow(0.3rem 0.3rem) drop-shadow(0 0 0.3rem #00FFFF80) }
	}

	.game-hero .sensuba-hero {

		filter: none;
	}

	.opposite-area .game-hero {

		top: calc(9.5vh - 3em);
	}
}