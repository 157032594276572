
.deck-viewer .card-dropper-inner {

  justify-content: center;
  align-items: center;
}

.deck-viewer .card-dropper-options {

  width: 90%;
  height: fit-content;
  padding: 6vh 0;
  margin-top: -6vh;
  max-height: 90%;
  align-content: center;
  overflow: auto;
  align-content: start;
}

.deck-viewer .card-dropper-option {

  font-size: calc(0.8vh + 1vw);
}