.gallery {

	width: 80%;
	height: calc(95% - 6rem);
	position: absolute;
	top: calc(2.5% + 5rem);
	left: 10%;
	flex-direction: column;
	color: #edeae4;
	align-items: center;
}

.gallery-sidebar {

    height: 5rem;
    width: 100%;
    overflow: auto;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: center;
}

.gallery-sidebar-inner {

    display: flex;
    align-items: center;
    max-width: 100%;
}

.gallery-champion-select {

}

.gallery-champion-select .gallery-champion-option {

	position: relative;
	height: 4rem;
	width: 4rem;
	background: #3f3f4c40;
	margin: 2px;
	transition: 0.15s ease-in-out background;
	cursor: pointer;
}

.gallery-champion-select .gallery-champion-option img {

	position: absolute;
	top: 0.2rem;
	left: 0.2rem;
	height: 3.6rem;
	width: 3.6rem;
	opacity: 0.6;
	filter: grayscale(50%);
	transition: 0.15s ease-in-out all;
}

.gallery-champion-select .gallery-champion-option.selected {

	cursor: default;
}

.gallery-champion-select .gallery-champion-option:hover, .gallery-champion-select .gallery-champion-option.selected {

	background: #3f3f4c80;
}

.gallery-champion-select .gallery-champion-option:not(.selected):active {

	background: #3f3f4cFF;
}

.gallery-champion-select .gallery-champion-option:hover img, .gallery-champion-select .gallery-champion-option.selected img {

	opacity: 1;
	filter: grayscale(0%);
}

.gallery-champion-select + .gallery-champion-select {

	margin-left: 1rem;
}

.gallery-type-select .gallery-champion-option img {

	filter: invert(1);
}

.gallery-champion-select.gallery-type-select .gallery-champion-option:hover img, .gallery-champion-select.gallery-type-select .gallery-champion-option.selected img {

	opacity: 1;
	filter: invert(1);
}

.gallery-main {

	height: calc(100% - 5.5rem);
	width: 100%;
	overflow: hidden;
}

.gallery-image-list {

	height: 100%;
	width: 100%;
	overflow: auto;
	flex-wrap: wrap;
	justify-content: center;
	align-content: baseline;
}

.gallery-image {

	position: relative;
	height: 12.16rem;
	width: 8.32rem;
	margin: 2px;
	background: #3f3f4c40;
	cursor: pointer;
	transition: background 0.15s ease-in-out;
}

.gallery-image.locked {

	background: #23232940;
	cursor: default;
}

.gallery-image:hover {

	background: #3f3f4c80;
}

.gallery-image.locked:hover {
	
	background: #23232980;
}

.gallery-image img {

	height: 100%;
	width: 100%;
	object-fit: cover;
}

.gallery-image.locked img {
	
	height: unset;
	object-fit: contain;
	width: 70%;
	opacity: 50%;
	margin: auto;
}

.gallery-image.locked .gallery-image-requirement, .gallery-image-name {

	position: absolute;
	height: 90%;
	width: 90%;
	top: 5%;
	left: 5%;
	z-index: 1;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	text-shadow: 1px 1px 0 #202020;
}

.gallery-image-name {

	font-size: 1.15rem;
}

.gallery .gallery-cutscene {

	transition: color 0.2s ease-in-out;
	cursor: pointer;
}

.gallery .gallery-cutscene:hover {

	color: #7b7c94;
}

.gallery-page .lightbox-container {

	background: #000000D0;
  	z-index: 20;
}

.gallery-lightbox {

	position: relative;
	height: 80vh;
	justify-content: center;
	align-items: center;
}

.gallery-lightbox .cardbox-arrow {
    top: calc(50% - 2rem);
    left: calc(30% - 10vh - 8rem);
}

.gallery-lightbox .cardbox-arrow.cardbox-arrow-right {
    left: unset;
    right: calc(30% - 10vh - 8rem);
}

.gallery-lightbox-image {

	position: relative;
	height: 100%;
	aspect-ratio: 832 / 1216;
	overflow: hidden;
}

.gallery-lightbox img {

	height: 100%;
	width: 100%;
	object-fit: cover;
}

.gallery-video img {

	filter: grayscale(1) brightness(0.5);
}

.gallery-lightbox-description {

	background: #c5cbd1c0;
	width: calc(8rem + 12vw);
	height: calc(90% - 4rem);
	margin-right: 1rem;
	border-radius: 1rem;
	padding: 1rem;
	font-size: 1rem;
	flex-direction: column;
	align-items: baseline;
	overflow: auto;
}

.gallery-lightbox-description::-webkit-scrollbar-track {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
}

.gallery-image-title {

	font-size: 1.2rem;
	font-weight: bold;
}

.gallery-description-section {

	display: block;
	margin-bottom: 0.2rem;
	text-align: left;
}

.gallery-description-section.description {

	line-height: 1.1rem;
	margin-top: 0.4rem;
}

.gallery-description-section * {

	display: inline;
}

.gallery-lightbox-select-emotion {

	position: absolute;
	top: 1rem;
	left: 0;
	width: 100%;
	height: 1.5rem;
	justify-content: center;
}

.gallery-lightbox-option-emotion {
	
	height: 1.5rem;
	width: 1.5rem;
	opacity: 0.3;
	background: #D0D0D0;
	border-radius: 50%;	
	margin: 0 0.4rem;
	transition: opacity 0.15s ease-in-out;
	cursor: pointer;
}

.gallery-lightbox-option-emotion.selected {
	
	cursor: default;
}

.gallery-lightbox-option-emotion:hover, .gallery-lightbox-option-emotion.selected {
	
	opacity: 0.8;
}

@media only screen and (max-width: 600px) {

	.gallery-lightbox {

		flex-direction: column-reverse;
		height: calc(80vh - 1rem);
	}

	.gallery-lightbox-image {

	    height: calc(80% - 5rem);
	}

	.gallery-lightbox-description {
		
	    height: calc(20% + 4rem);
	    width: 80%;
	    margin-right: 0;
	    margin-top: 1rem;
	}

	.gallery-lightbox .cardbox-arrow {

	    left: calc(30% - 6rem);
	}

	.gallery-lightbox .cardbox-arrow.cardbox-arrow-right {

	    left: unset;
	    right: calc(30% - 6rem);
	}
}