.fight-loot-card-selector {

  flex-direction: column;
  margin: auto;
  margin-bottom: -8vh;
  align-items: center;
}

.fight-loot-card-skip {

  cursor: pointer;
  color: white;
  font-size: calc(1.8vh + 0.5vw);
  margin-top: 3vh;
  padding: 1vh 1vw;
  transition: color 0.1s ease-in-out;
}

.fight-loot-card-skip:hover {

  color: #7b7c94;
}

.fight-loot-card {

  background: #000000D0;
  z-index: 20;
}

.fight-loot-card-options {

  width: 80vw;
  margin: auto;
  justify-content: space-between;
}

.fight-loot-card-option {

  flex-direction: column;
  align-items: center;
}

.fight-loot-card-option .fight-loot-card-option-card {

  margin-bottom: 2rem;
  cursor: pointer;
  width: 6.5em;
  height: 9.5em;
}

.fight-loot-card-option .fight-loot-card-option-card .fight-loot-card-option-card-inner {

  margin-left: -0.2em;
  transition: margin 0.2s, font-size 0.2s;
}

.fight-loot-card-option .fight-loot-card-option-card:hover .fight-loot-card-option-card-inner {

  margin-left: -0.5em;
  margin-top: -0.5em;
  font-size: 1.1em;
}

.fight-loot-card-option .game-card-info {

  position: static;
  width: calc(10vw + 20vh);
  height: calc(5vw + 15vh);
  flex-direction: column;
  background: #1f1f23;
  border-radius: 2px;
  padding: calc(1vw + 1.5vh);
  padding-top: calc(0.66vw + 1vh);
  box-sizing: border-box;
  box-shadow: 0px 0px 4px 0px rgba(100, 100, 100, 0.35);
  color: #edeae4;
  justify-content: center;
  font-size: calc(0.9vw + 1.4vh);
}

.game-card-info-inner-selector {

  flex-direction: column;
}

@media only screen and (max-height: 550px) {

  .fight-loot-card-option .fight-loot-card-option-card {

    font-size: 0.8em;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .fight-loot-card-selector {
    margin-bottom: -12vh;
  }
}