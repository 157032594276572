.progress-page main {

	height: 100vh;
	width: 100vw;
}

.story-stats {

	width: 100%;
	flex-direction: column;
}

.story-stat {

	width: 100%;
}

.story-stat div:nth-child(1) {
	
	width: 70%;
}

.story-stat div:nth-child(2) {
	
	width: 30%;
	text-align: right;
	justify-content: end;
}

.story-stats .story-embark {

	justify-content: center;
	width: fit-content;
    margin: auto;
    margin-top: 0.5rem;
}

.progress-run {

	z-index: 20;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.progress-run-date {

	position: absolute;
	color: white;
	height: 10vh;
	width: 80vw;
	justify-content: center;
	top: 5vh;
	left: 10vw;
	z-index: 21;
	pointer-events: none;
}

.progress-run-stats {

	position: absolute;
	color: #D0D0D0;
	height: 10vh;
	width: 80vw;
	justify-content: center;
	top: 12vh;
	left: 10vw;
	z-index: 21;
	pointer-events: none;
}

.progress-run-stats .progress-run-stat {

	margin: 0 1rem;
}

.progress-run .cardbox-arrow {

	z-index: 23;
	top: calc(50% - 2rem);
	left: 2vw;
}

.progress-run .cardbox-arrow.cardbox-arrow-right {

	left: unset;
	right: 2vw;
}