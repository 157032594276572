.remove-from-hand-anim {

	animation: remove-from-hand 0.2s ease-in forwards;
}

.fastmode .remove-from-hand-anim {

  animation: remove-from-hand 0.08s ease-in forwards;
}

@keyframes remove-from-hand {
  from { top: 0em }
  to { top: 8em }
}