.main-button-container {

	justify-content: center;
	margin: 1rem;
}

.main-button-wrapper {

	overflow: hidden;
}

.main-button {

	font-size: 1.8rem;
	padding: 0.5rem;
	min-width: 8rem;
	justify-content: center;
	border-width: 0.1rem;
	background: linear-gradient(to bottom, #b7b8cf, #d6d9e6);
	position: relative;
	transition: all 0.2s linear;
	cursor: pointer;
}

.main-button::after {

	content: "";
	position: absolute;
	top: -5%;
	left: 120%;
	width: 120%;
	height: 110%;
	transform: skewX(-20deg);
	background: linear-gradient(to bottom, #1d1c30, #33354f);
	transition: all 0.2s linear;
}

.main-button:hover {

	color: #edeae4;
	transition: all 0.2s linear;
}

.main-button:hover::after {

	left: -10%;
	transition: all 0.2s;
}

.main-button-text {

	z-index: 1;
}

button {

	margin: 0;
	padding: 0;
	border-width: 0;
}



.main-button-container.red-color .main-button {

	background: linear-gradient(to bottom, #6c1d1d, #964646);
	color: white;
	border-top-color: #dddddd;
	border-left-color: #dddddd;
	border-bottom-color: #aaaaaa;
	border-right-color: #aaaaaa;
}

.main-button-container.red-color .main-button:after {

	background: linear-gradient(to bottom, #340404, #510808);
}

.main-button-container.patreon-color .main-button {

	background: linear-gradient(to bottom, #A0A0A0, #C0C0C0);
}

.main-button-container.patreon-color .main-button:after {

	background: linear-gradient(to bottom, black, #202020);
}

.main-button-container.discord-color .main-button {

	background: linear-gradient(to bottom, #888fdb, #a3accc);
}

.main-button-container.discord-color .main-button:after {

	background: linear-gradient(to bottom, #393F87, #768DDB);
}

.main-button-container.itchio-color .main-button {

	background: linear-gradient(to bottom, #ee899a, #f0c0c0);
}

.main-button-container.itchio-color .main-button:after {

	background: linear-gradient(to bottom, #FF2B4F, #FA6161);
}

.main-button-container.twitter-color .main-button {

	background: linear-gradient(to bottom, #61b3d1, #a7e4f7);
}

.main-button-container.twitter-color .main-button:after {

	background: linear-gradient(to bottom, #0888B6, #24CCFF);
}