.gg-champion {

	justify-content: center;
	width: 6.5em;
	height: 9.5em;
	position: relative;
  	filter: drop-shadow(#00000060 0.15rem 0.15rem);
}

.gg-champion img {

	object-fit: cover;
}

.champion-size-1 .gg-champion {

	font-size: 1.25em;
}

.champion-size-2 .gg-champion {

	font-size: 1.5em;
}

.champion-size-3 .gg-champion {

	font-size: 1.75em;
}

.champion-size-4 .gg-champion {

	font-size: 2em;
}