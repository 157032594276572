.error-page .main {

	height: 100%;
	width: 100%;
}

.story-text-wrapper {

	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	opacity: 1;
	transition: opacity 0.5s;
}

.story-text {

	width: 32rem;
	max-width: 90vw;
	justify-content: center;
	color: /*#edeae4*/#dec6a2;
}

.main-page.light .story-text {

	color: #301f1c;
}

.story-text-wrapper.fade-text {

	opacity: 0;
}