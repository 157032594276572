.game-field {

	position: absolute;
	width: 100vw;
	height: 80vw;
	top: calc(25vh - 10vw);
	left: 0;
	background: linear-gradient(to bottom, transparent, #c4c4c420, transparent);
	transition: background 0.2s, box-shadow 0.2s;
}

.inner-field {

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.game-field-filter {

	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: #e2ae9400;
	transition: background 0.2s, box-shadow 0.2s;
}

.game-field.game-field-target .game-field-filter {

	background: #e2ae9460;
	animation: column-target-shadow 1s alternate infinite;
}

.game-field.game-field-targetable .game-field-filter {

	background: #e2ae9420;
	animation: column-target-shadow 1s alternate infinite;
}

.game-field-column {

	width: 100%;
	height: 100%;
	/*background: #ffecbf;*/
	/*box-shadow: inset 0 0 1rem #533a1180;*/
	flex-direction: column;
	justify-content: space-evenly;
	transition: background 0.2s, box-shadow 0.2s;
	position: relative;
}

.game-field-column::before {

	content: "";
	width: 2px;
	height: 100%;
	position: absolute;
	top: 0;
	left: -1px;
	background: linear-gradient(to bottom, transparent, #edeae4, transparent);
}

.game-field-column:nth-child(1)::before {

	content: none;
}

.game-field-column.game-field-column-target {

	background: #e2ae94;
	animation: column-target-shadow 1s alternate infinite;
}

.game-field-column.game-field-column-targetable {

	background: #e2ae9420;
	animation: column-target-shadow 1s alternate infinite;
}

@keyframes column-target-shadow {
	from { box-shadow: inset 0 0 1rem #AA0000A0; }
	to { box-shadow: inset 0 0 1rem 0.1rem #AA0000D0; }
}

.game-field-tile {

	width: 100%;
	height: 50%;
	flex-direction: column;
	font-size: 2vw;
	align-items: center;
	justify-content: flex-end;
	padding-bottom: 4vw;
	padding-top: unset;
}

.game-field-tile:nth-child(1) {

	flex-flow: column-reverse;
	padding-bottom: unset;
	padding-top: 4vw;
}

.game-field-tile .game-card-wrapper {

	margin: 2vw 0;
}

@media (min-width: 601px) {

	.game-field {

		height: 56vh;
		top: 14.5vh;
		left: unset;
	}

	.inner-field {

		width: 150vh;
		max-width: 100%;
		left: calc(50% - 75vh);
	}

	.game-field-tile {

	    font-size: 1.65vh;
	}

	.game-field-tile:nth-child(1) {
		
	    padding-top: calc(4vh - 0.2vw);
	    padding-bottom: 0vh;
	}

	.game-field-tile:nth-child(2) {

	    padding-top: 0vh;
	    padding-bottom: calc(4vh - 0.2vw);
	}

	.game-field-tile .game-card-wrapper {

	    margin: calc(1vh - 0vw) 0;
	}
}