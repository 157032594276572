.time-down-anim .time-down.lightbox-container {

  display: inherit;
  opacity: 1;
  animation: time-down-appear 1s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes time-down-appear {
  0% { opacity:0 }
  20% { opacity:1 }
  80% { opacity:1 }
  100% { opacity:0 }
}

.time-down-anim .time-down.lightbox-container .time-down-img {

  animation: time-down-rotate 0.4s ease-in-out;
  animation-delay: 0.166s;
  animation-fill-mode: forwards;
}

@keyframes time-down-rotate {
  from { transform: rotateZ(0deg) }
  to { transform: rotateZ(180deg) }
}

.fastmode.time-down-anim .time-down.lightbox-container {

  animation: time-down-appear 0.33s ease-in-out;
  animation-fill-mode: forwards;
}

.fastmode.time-down-anim .time-down.lightbox-container .time-down-img {

  animation: time-down-rotate 0.166s ease-in-out;
  animation-delay: 0.05s;
  animation-fill-mode: forwards;
}

.time-down-anim .fight-scene {

  animation: time-down-disappear 1s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes time-down-disappear {
  from { opacity:1 }
  to { opacity:0 }
}

.fastmode.time-down-anim .fight-scene {

  animation: time-down-disappear 0.33s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes time-down-disappear {
  from { opacity:1 }
  to { opacity:0 }
}